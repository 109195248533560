

















































































































































































import Vue from 'vue'

export default Vue.extend({
	name: 'TakeAway',
	props: {
		question: {
			type: Object,
			required: true,
		},
		answeredCorrectly: {
			type: Boolean,
			default: false,
		},
	},
})
