/* eslint-disable max-len */

export default [
	{
		id: 1,
		type: 1,
		color: '#047fc7',
		points: 300,
		correct: null,
		group: 'Phishing',
		title: '„Wenn ich jemanden in die Irre führen will, dann mach ich das!“',
		options: ['stehlen', 'täuschen', 'verstecken'],
		correctOptionIndex: 1,
		case: {
			headline: 'Ransomware-Attacke auf KME',
			whatHappened: [
				'Der deutsche Kupferhersteller KME ist 2020 Opfer einer Ransomware - Attacke geworden',
				'Eingeschleust wurde ein auf Industrieanlagen ausgelegter Verschlüsselungstrojaner, der Teile des Produktionsprozesses stillgelegt gelegt ',
				'Die Hacker haben eine Lösegeldforderung in Millionenhöhe platziert',
				'Wahrscheinliche Ursachen: Erworbene Login - Daten ins Firmennetzwerk aus dem Darknet plus ein unsicheres Passwort eines Administrators',
			],
		},
		takeAway: {
			headline: 'Täuschung ist ein zentrales Merkmal von Angriffstaktiken, mit denen Cyber-Kriminelle Schadsoftware verbreiten',
			list: [
				'Hauptsächlich per E-Mail und täuschen die Empfänger mit relevant erscheinenden Anlass.',
				'Stell Dir grundsätzlich vor dem Öffnen einer E-Mail diese Fragen:<br /><ul><li>Erwarte ich diese Mail wirklich, ist sie plausibel, stammt sie von einem seriösen Absender?</li><li>Ist die E-Mail-Adresse wirklich „echt“ und gehört sie dem Absender, den ich kenne?</li></ul>',
				'Öffne niemals Anhänge und klicke nie auf Links, ohne die E-Mail-Adresse geprüft zu haben:<ul><li>Vorsicht: Spoofing! Betrüger täuschen oft eine Ihnen bekannte E-Mail-Adresse vor.</li><li>Klicke auf den Namen des Absenders, um die tatsächliche E-Mail-Adresse zu sehen.</li></ul>',
			],
		},
	},
	{
		id: 2,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Arbeiten von Zuhause',
		title: 'In welcher Situation sind Informationen von UNIPER bei der Arbeit von zuhause einem erhöhten Risiko ausgesetzt?',
		options: [
			'Die Fragestellung ist schlichtweg falsch, denn die Gefahr eines erfolgreichen Angriffs ist immer gleich hoch.',
			'Beim Zugriff über ein privates Gerät via gesicherter VPN-Leitung.',
			'Wenn ich unerlaubterweise berufliche Informationen an meinen privaten E-Mail-Account sende.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Keinesfalls berufliche Informationen an die private E-Mail Adresse senden.',
			list: [
				'Für den sicheren Zugriff auf die Systeme vpn nutzen.',
				'Empfehlungen für die Sicherheit zuhause:<ul><li>WPA2 - Verschlüsselung des WLAN aktivieren.</li><li>Fürs WLAN ein starkes Passwort mit mehr als 20 Zeichen verwenden.</li><li>Auf privaten Geräten Betriebssystem und Virenschutz - Programme regelmäßig aktualisieren.</li></ul>',
				'Worauf es zuhause physisch ankommt:<ul><li>Für die Arbeit einen möglichst gut geschützen Platz aufsuchen, der Fremden keinen Einblick oder ein Mithören ermöglicht.</li></ul>',
			],
		},
	},
	{
		id: 3,
		type: 2,
		color: '#01b0e8',
		points: 500,
		correct: null,
		group: 'Phishing',
		title: 'Welche dieser Dateiarten werden von Cyber-Kriminellen am häufigsten zur Verbreitung von Schadsoftware verwendet?',
		options: [
			'PDF-Dateien',
			'MP4-Dateien',
			'Word-, Powerpoint- und Excel-Dateien',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Sei besonders vorsichtig, wenn Du beim Öffnen einer Datei aufgefordert wirst, Makros zu aktivieren!',
			list: [
				'Öffne solche Dateien nie, ohne sich beim Absender einer seriösen Herkunft dieser Dateien versichert zu haben!',
				'Um fremde Geräte und Netzwerke zu infizieren, greifen die Täter am häufigsten per E-Mail an, zunehmend aber auch über Social-Media-Posts, außerdem über Messenger und Textnachrichten (SMS).',
			],
		},
	},
	{
		id: 4,
		type: 4,
		color: '#6bb51e',
		points: 500,
		correct: null,
		group: 'Social Engineering',
		title: 'Fünf verschiedene PHASEN – welche gehört NICHT zum typischen Vorgehen eines Social Engineers?',
		text: '<ol><li>In Kontakt treten, eine Beziehung aufbauen</li><li>Informationen sammeln</li><li>Für die Zielperson telefonisch ständig erreichbar sein</li><li>Informationen weiterleiten</li><li>Rückzug</li></ol><p>Erkenne die vier Phasen und bring sie in die richtige Reihenfolge.</p>',
		options: [
			'Reihenfolge<br />1, 2, 3, 5',
			'Reihenfolge<br />2, 1, 4, 5',
			'Reihenfolge<br />1, 2, 4, 5',
		],
		correctOptionIndex: 1,
		takeAway: {
			headline: 'Beim Spear Phishing wird der Empfänger gezielt ausgewählt und individuell angegriffen',
			list: [
				'Die nötigen Informationen dafür verschaffen sich die Täter in der ersten Phase des Social Engineerings durch Recherchen in öffentlichen Quellen.',
				'Phase 2: Im Vorfeld bauen sie dank der gesammelten Informationen eine Beziehung zur Zielperson auf.',
				'Phase 3: Jetzt verwerten sie Beziehung und Informationen, finden einen erfolgversprechenden Angriffspunkt.',
				'Phase 4: Die Täter verwischen Spuren, anhand derer sich ihre Identtät ermitteln lassen könnte. Social Engineers geben einen plausibel erscheinenden Grund für den Abbruch des Kontakts an, damit Zielpersonen keinen Verdacht schöpfen.',
			],
		},
	},
	{
		id: 5,
		type: 3,
		color: '#00a1e5',
		points: 300,
		correct: null,
		group: 'Social Engineering',
		title: '„Social Engineering ist auch ein Erfolgsfaktor für sogenanntes Business E-Mail Compromise, wahr oder falsch?“',
		text: '„Dazu senden Betrüger gut gefälschte E-Mails, die scheinbar von einem Mitglied der Unternehmensführung oder etwa von Geschäftspartnern stammen, an ausgesuchte Personen. Sie sollen zu einer Geldüberweisung an ein betrügerisches Konto bewegt werden.“',
		correctAnswer: true,
		case: {
			headline: 'Norwegens Vermögensfond Norfund verliert 10 Millionen Dollar durch Business E-Mail Compromise (BEC)',
			whatHappened: [
				'Norfund erhält sein Investitionskapital aus dem Staatshaushalt und ist der größte Staatsfonds der Welt.',
				'Der Fonds gab an, dass via Social Enginneering / BEC ein Schaden von 10 Millionen US - Dollar entstanden ist. ',
				'Mit Hilfe einer Mischung aus manipulierten Daten und gefälschten Informationen gelang es den Betrügern, sich als das kreditgebende Institut auszugeben und Gelder vom echten Empfänger in die eigene Tasche abzuzweigen. ',
				'Ein Schlüssel „zum Erfolg“ der Hacker war es, dass ein leitender Angestellter getäuscht werden konnte.Diese Person veranlasste schließlich, das Geld auf ein von den Hackern kontrolliertes Konto zu überweisen.',
			],
		},
		takeAway: {
			headline: 'Vertrauensaufbau als Schlüssel zum Erfolg.',
			list: [
				'Bei Business E-Mail Compromise werden die ausführenden Mitarbeiter zusätzlich unter Zeitdruck gesetzt und auf die Geheimhaltung der Überweisung hingewiesen. Bei dem vorliegenden Fall aus Norwegen haben die Täter allerdings bewusst Geduld gezeigt, um Vertrauen aufzubauen und ihre langfristigen Ziele akribisch verfolgt.“',
				'Social Engineers spähen Sie und Ihr Arbeitsumfeld vor der Kontaktaufnahme online aus. Geben Sie daher weder auf Social-Media-Plattformen noch in Job-Portalen sensible private oder beruf-liche Informationen unbekannten Personen gegenüber oder gar öffentlich preis.',
			],
		},
	},
	{
		id: 6,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Malware',
		title: 'Wie schätzt Du diese E-Mail ein und wie verhältst Du dich?',
		text: '<p>„Kommen wir zu einem möglichen Anfang des Norfund-Falls. Nehmen wir einmal an, Du bist der Mitarbeiter, den Cyber-Kriminelle als Zielperson indentifiziert haben.“</p><p>„Du sitzt also vor dem Rechner, es ist stressig und plötzlich kommt eine E-Mail von einem Arbeitskollegen mit hoher Dringlichkeit. Wie so oft muss es schnell gehen. Schau genau hin…</p><p>Zwei Dinge sind in der gezeigten Grafik - Mail auffällig:</p><li>Es handelt sich nicht um eine UNIPER-E-Mail-Adresse</li><li>Im Anhang befindet sich eine.PPTX - Datei.</li></ol>',
		options: [
			'Nichts Besonderes, scheint alles ok zu sein. Die E-Mail sieht wie eine UNIPER-Mail aus und der PPTX-Dateianhang erscheint mir unverdächtig.',
			'Die E-Mail-Adresse des Absenders ist keine von UNIPER. Aber viele Kollegen arbeiten derzeit von zuhause aus. Da kann es passieren, dass ein Kollege unter seiner privaten Adresse mailt.',
			'Der E-Mail-Adresse des Absenders würde ich nicht trauen und Power-Point-Anhänge sind potenzielle Gefahrenquellen.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Werde skeptisch, wenn Du aufgefordert wirst, umgehend zu handeln.',
			list: [
				'Lass Dich sich nicht unter Druck setzen – erst recht nicht bei Androhung von Konsequenzen.',
				'Nimm Dir die Zeit, die Fakten zu klären! ',
			],
		},
	},
	{
		id: 7,
		type: 2,
		color: '#f58f00',
		points: 1000,
		correct: null,
		group: 'Phishing',
		title: 'Welches ist das stärkere Passwort?',
		text: '„Es gilt in jedem Fall, es den Kriminellen so schwer wie möglich zu machen, damit der Zugriff auf den Rechner, das Netzwerk oder die Systeme nicht möglich ist. Das ist im Falle Norfund so, aber auch hier bei uns bei UNIPER. Ein starkes Passwort ist immer noch eine der wichtigsten Vorkehrungen. Einfache Frage: Welches ist das stärkere Passwort?',
		options: [
			'Iordermypizza@therestaurant4YOUnow!',
			'P04?§%\\wZ8@',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Nutze ein starkes Passwort, das Du mit niemandem teilst.',
			list: [
				'Es sollte mindestens aus zwölf Zeichen (Buchstaben, Ziffern und Sonderzeichen) bestehen und nicht aus dem Wörterbuch stammen.',
				'Alternativ kannst Du Wörter oder Sätze verfremden (Beispiel: 2_SuPpentellA“).',
				'Ein Passwort-Manager hilft beim Aufsetzen und auch beim Merken von sicheren Passwörtern. Bei Uniper können wir in SNOW im Softwarestore KeePass kostenfrei beziehen.',
			],
		},
	},
	{
		id: 8,
		type: 4,
		color: '#d3c002',
		points: 1000,
		correct: null,
		group: 'Social Engineering',
		title: '„Das hat was mit Gefühl zu tun!“',
		text: 'Das wirkliche Besondere an dem Fall „Norfund“ ist die Tatsache, dass die Täter über mehrere Monate den Kontakt gehalten haben und sogar nach der ersten erfolgreichen Überweisung einen zweiten Anlauf genommen haben, um weitere Gelder zu ergaunern. Hier steht ein großes Thema des Social Engineerings im Vordergrund, das diese Betrugsform so erfolgreich macht. Was ist gesucht?',
		options: [
			'Emotion',
			'Willenskraft',
			'Konzentration',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Vorsicht, Attacke auf Deine Gefühle!',
			list: [
				'Ganz egal, ob per E-Mail, am Telefon, via Textnachricht (SMS), Messenger oder Social Media Post: Werde skeptisch, sobald eine Nachricht Dich binnen Sekunden emotional bewegt und Dich auffordert, umgehend zu handeln.',
			],
		},
	},
	{
		id: 9,
		type: 2,
		color: '#8cd4f4',
		points: 1000,
		correct: null,
		group: 'Malware',
		title: 'Was versteht man unter „Drive-by-Download“?',
		text: 'Emotionen spielen beim Thema Social Engineering eine entscheidende Rolle – zum Beispiel soll man bewusst dazu gebracht werden, auf einen bestimmten Link zu klicken …',
		options: [
			'Ein Drive-by-Download ist das unbewusste und unbeabsichtigte Herunterladen von Software auf einen Rechner – im „Vorbeifahren“ wird ohne aktive Kenntnis des Nutzers eine Datei heruntergeladen.',
			'Beim aktiven Download, z. B. von Filmdateien oder Musikdateien aus den etablierten App-Portalen, werden bewusst Schadprogramme mit „eingeschleust“.',
			'Dieser Download ist glücklicherweise etwas Gutes – es ist schlicht und ergreifend eine effektive Anti-Viren-Software.',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Grundsätzlich nicht unüberlegt klicken!',
			list: [
				'Sowohl bei der Nutzung von privaten als auch dienstlichen Endgeräten gilt:',
				'Nie auf verdächtige Links klicken – immer auf vertrauenswürdige Plattformen achten.',
				'Zum Schutz solltest Du immer die <b>aktuellsten Versionen</b> des Browsers sowie aller Plug-ins (Hilfsprogramme, welche die Funktionalitäten des Browsers erweitern) verwenden.',
			],
		},
	},
	{
		id: 10,
		type: 2,
		color: '#f58f00',
		points: 300,
		correct: null,
		group: 'Arbeiten von Zuhause',
		title: 'Welche dieser drei Personengruppen hat ein verstärktes Interesse an „Unbefugtem Zugang“?',
		text: 'Die Situation ist ein klein wenig vergleichbar mit der Situation „Arbeiten von zuhause“ – nicht das Hauptunternehmen ist angegriffen worden, sondern quasi „Außeneinheiten“. Und dieser Umstand erfordert ganz einfach besondere Aufmerksamkeit…',
		options: [
			'Hacker / Krimineller',
			'Politischer Aktivist',
			'Aufgebrachter Ex-Mitarbeiter',
			'alle',
		],
		correctOptionIndex: 3,
		case: {
			headline: 'Datenpanne bei der Marriott Hotelkette',
			whatHappened: [
				'Zweite Datenpanne innerhalb kürzester Zeit bei diesem Unternehmen',
				'5, 2 Millionen Gäste betroffen',
				'Zwei Mitarbeiter eines Franchise - Nehmers der Hotelkette waren offenbar das Einfallstor für Datendiebe',
				'Mutmaßlich verschafften sich die Angreifer Zugang zu den Login - Daten der genannten Mitarbeiter',
				'Durch das Leck flossen sensible Daten der Hotelkunden ab, u.a. Ausweisnummern und Bezahlinformationen',
			],
		},
		takeAway: {
			headline: 'Unterschiedliche Täter, unterschiedliche Interessen',
			list: [
				'Allen diesen Personengruppen geht es in der Regel darum, UNIPER oder unseren Kunden zu schaden:<ol><li>Wirtschaftsspione > Weitergabe von Unternehmensinterna etwa an fremde Regierungen oder Mitbewerber</li><li>Kriminelle > Diebstahl etwa von Wertgegenständen, Smartphones und Tablets</li><li>Politische Aktivisten > Erregen öffentlichen Interesses, Zerstörung von Eigentum</li><li>Terroristen > Gewalttaten in Institutionen (Filialen) und gegen Personen</li><li>Missgünstige Kunden und Mitbewerber > Erlangung von Informationen und Aufmerksamkeit, um eigene Interessen durchzusetzen</li><li>Aufgebrachte ehemalige Mitarbeiter > Gezieltes Entwenden sensibler Daten, um dem Unternehmen Schaden zuzufügen</li></ol>',
			],
		},
	},
	{
		id: 11,
		type: 2,
		color: '#d3c002',
		points: 300,
		correct: null,
		group: 'Malware',
		title: 'Was ist eigentlich das Synonym von Malware, was ist damit gemeint?',
		text: 'Klar, Malware ist nie gewollt, sondern verschafft sich durch die ausgeklügelten Angriffe von Kriminellen quasi „unerlaubten Zugang“.',
		options: ['Schadprogramm', 'Schadsoftware', 'Schadsoftware, Schadprogramm und Malware sind identische Bezeichnungen'],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Die Begriffe Schadsoftware, Schadprogramm oder Malware sind Synonyme.',
			list: [
				'Die Begriffe Schadsoftware, Schadprogramm oder Malware sind synonyme Oberbegriffe für eine Vielzahl unterschiedlicher Computerprogramme, die dazu dienen, unbefugt in Computer und Netzwerke einzudringen, um auf fremde Daten und Informationen zuzugreifen.<br />Wesentliche Charakteristika:<ol><li>Ausnutzen von Schwachstellen, um eigene Interessen durchzusetzen</li><li>Programmcode wird maskiert oder fortlaufend verändert, um technische Schutzsysteme zu umgehen</li><li>Entwicklung und Einsatz finden meist arbeitsteilig statt, d.h.die Verfügbarkeit im Darknet vervielfacht die Bedrohung immens</li></ol>',
			],
		},
	},
	{
		id: 12,
		type: 2,
		color: '#047fc7',
		points: 1000,
		correct: null,
		group: 'Arbeiten von Zuhause',
		title: 'Was bedeutet Informationsklassifizierung NICHT?',
		text: 'Der zweite Angriff auf Marriott hat gezeigt, wie wichtig der Schutz der eigenen Daten ist – im Büro, aber auch wenn man von zuhause arbeitet. Informationsklassifizierung hilft, die eigenen Daten zu schützen. ',
		options: [
			'Die freie Wahl eines Telefon- oder Videokonferenzanbieters',
			'Eigene Bewertung der Schutzbedürftigkeit der Information und Zuordnung einer Klassifizierungsstufe',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Informationsklassifizierung als wichtiges Prinzip',
			list: [
				'Grundsätzlich: UNIPER hat ein konzernweites Prinzip der Klassifizierung von Informationen eingeführt, um den Schutz von Informationen zu gewährleisten und das Risiko von Datenverlusten zu verringern.',
				'Sobald Du eine Information erstellst, veränderst oder aus externer Quelle speicherst oder weiterleitest, gilt: Es liegt in Deiner Verantwortung, ihre Schutzbedürftigkeit zu bewerten.',
				'Das Prinzip der Informationsklassifizierung gilt auch für berufliche Telefonate und Videokonferenzen, die Du zuhause führst. Behandle diese Gespräche vertraulich, sobald es um geschäftliche Informationen geht.',
				'Bei Uniper verwenden wir für Video- und Telefonkonferenzen grundsätzlich Teams. Bei notwendigen Alternativen kannst Du Deinen verantwortlichen Information Security Officer (ISO) kontaktieren.',
			],
		},
	},
]
