










































import Vue from 'vue'
import { GridItems } from '@/data/Questions'

export default Vue.extend({
	name: 'OverviewGrid',
	props: {
		next: {
			type: Number,
			default: 0,
		},
		questions: {
			type: Array,
			default: () => ([]),
		},
	},
	data() {
		return {
			gridItems: GridItems,
		}
	},
	methods: {
		select(index: number) {
			console.log(index)
			this.$emit('select', index)
		},
	},
})
