var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showInfo)?_c('info',{attrs:{"question":_vm.question},on:{"continue":function($event){_vm.showInfo = false}}}):(_vm.showTakeAway)?_c('take-away',{attrs:{"answeredCorrectly":_vm.answeredCorrectly,"question":_vm.question},on:{"continue":function($event){return _vm.$emit('done', _vm.answeredCorrectly)}}}):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col sidebar"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo.svg")}}),_c('div',{staticStyle:{"flex-grow":"1","display":"flex","justify-content":"center","align-items":"center","padding-left":"1rem"}},[_c('div',[_c('h1',{staticClass:"blue",staticStyle:{"margin-bottom":"0"}},[_vm._v(_vm._s(_vm.$t('question'))+" "+_vm._s(_vm.question.index + 1))]),_c('h2',{staticClass:"green",staticStyle:{"margin-top":"0"}},[_vm._v(_vm._s(_vm.question.group)+" "+_vm._s(_vm.question.points))]),(_vm.question.id === 8 && _vm.$i18n.locale === 'de')?_c('img',{staticStyle:{"width":"100%","max-width":"650px","min-width":"400px"},attrs:{"src":require("@/assets/img/social-engineering-1000.jpg")}}):(_vm.question.id === 1)?_c('img',{staticStyle:{"width":"100%","max-width":"550px","min-width":"250px"},attrs:{"src":require("@/assets/img/question-1.jpg")}}):_c('div',{staticClass:"moderator"},[_c('img',{attrs:{"src":require("@/assets/img/moderator.svg")}}),_c('div',[_c('label',[_vm._v(_vm._s(_vm.question.group)+" "+_vm._s(_vm.question.points))])])])])])]),_c('div',{staticClass:"col content"},[_c('main',[([1, 2, 4].includes(_vm.question.type))?_c(("question-content-" + (_vm.question.type)),{tag:"component",attrs:{"question":_vm.question},on:{"select":function (i) {
					if (i === _vm.question.correctOptionIndex) {
						_vm.correct()
					} else {
						_vm.incorrect()
					}
				}}}):_vm._e(),(_vm.question.type === 3)?_c('question-content-3',{attrs:{"question":_vm.question},on:{"select":function (s) {
					if (s === _vm.question.correctAnswer) {
						_vm.correct()
					} else {
						_vm.incorrect()
					}
				}}}):_vm._e(),_c('button',{staticStyle:{"margin-top":"2rem"},attrs:{"disabled":_vm.blockGoBack},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('back-to-knowledge-wall-btn'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }