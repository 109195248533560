/* eslint-disable max-len */

export default [
	{
		id: 1,
		type: 1,
		color: '#047fc7',
		points: 300,
		correct: null,
		group: 'Phishing',
		title: '„HA VALAKIT FÉLRE AKAROK VEZETNI, AKKOR AZT MEG IS TESZEM!”',
		options: ['lopás', 'megtévesztés', 'elrejtés'],
		correctOptionIndex: 1,
		case: {
			headline: 'Zsarolóprogram-támadás a KME-nél',
			whatHappened: [
				'A német rézgyártó üzemet, a KME-t 2020-ban zsarolóprogrammal támadták meg.',
				'Becsempésztek egy ipari berendezésekhez készített, titkosítást végző trójai programot, amely megbénította a gyártási folyamatok egy részét.',
				'A hackerek milliós nagyságrendű összeg kifizetését követelték.',
				'Valószínűsíthető okok: A cég hálózatához tartozó bejelentkezési adatok megszerzése a darknetről, valamint az egyik rendszergazda nem biztonságos jelszava.',
			],
		},
		takeAway: {
			headline: 'A megtévesztés a támadási taktikák egy központi eleme, amelynek köszönhetően terjedni tudnak a kiberbűnözők kártékony programjai.',
			list: [
				'A támadók főleg e-mailben tevékenykednek, és fontosnak tűnő indokkal megtévesztik a címzettet.',
				'Az e-mailek megnyitása előtt alapvetően a következő kérdéseket kell feltenned magadnak:<br /><ul><li>Tényleg várom ezt az e-mailt? Igazinak tűnik? Komolyan vehető feladótól származik?</li><li>Tényleg „igazi” az e-mail-cím, és az általam ismert feladóhoz tartozik?</li></ul>',
				'Az e-mail-cím ellenőrzése előtt semmiképp ne nyiss meg csatolmányokat, és ne kattints a linkekre:<ul><li>Figyelem: Spoofing, vagyis e-mail-cím hamisítás! A csalók gyakran azt a látszatot keltik, hogy ismerős e-mail-címről küldték a levelet.</li><li>Kattints a feladó nevére a tényleges e-mail-cím megtekintéséhez.</li></ul>',
			],
		},
	},
	{
		id: 2,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Otthoni munkavégzés',
		title: 'Mely helyzetekben vannak kitéve kiemelt kockázatnak a UNIPER-től származó információk otthoni munkavégzés esetén?',
		options: [
			'A kérdés egyszerűen rossz, ugyanis a sikeres támadás veszélye mindig ugyanolyan magas.',
			'Amikor privát eszközön keresztül férek hozzá a munkaanyagokhoz védett VPN-kapcsolatot használva.',
			'Amikor engedély nélkül munkával kapcsolatos információkat továbbítok a privát e-mail-fiókomba.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Semmiképp ne küldj munkával kapcsolatos információkat privát e-mail-címre.',
			list: [
				'Az otthoni biztonságra vonatkozó ajánlások:<br />> Aktiváld a Wi-Fi WPA2-titkosítását.<br />> A Wi-Fi-hez erős, több mint 20 karakterből álló jelszót használj.<br />> A privát operációs rendszereken mindig legyenek frissítve a vírusirtó programok.',
				'Ami az otthoni munkavégzést illeti fizikai szempontból:<br />> A munkavégzéshez minél jobban védett helyet keress, amelyre nem láthatnak rá az illetéktelenek, és ahol a hallgatózás sem lehetséges.',
			],
		},
	},
	{
		id: 3,
		type: 2,
		color: '#01b0e8',
		points: 500,
		correct: null,
		group: 'Phishing',
		title: 'Ezek közül mely fájltípusokat használják a leggyakrabban a kiberbűnözők kártékony szoftverek terjesztéséhez?',
		options: [
			'PDF-fájlok',
			'MP4-fájlok',
			'Word-, PowerPoint- és Excel-fájlok',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Légy különösen óvatos, amikor egy fájl megnyitásakor megkér a rendszer a makrók aktiválására!',
			list: [
				'Semmiképp ne nyiss meg ilyen fájlokat anélkül, hogy a feladóval egyeztetve meg nem győződsz a fájlok eredetéről!',
				'Az idegen eszközök és hálózatok megfertőzéséhez a támadók leggyakrabban e-maileket használnak, de egyre gyakrabban nyúlnak közösségimédia-bejegyzésekhez is, valamint Messenger-üzenetekben és SMS-ben is támadhatnak.',
			],
		},
	},
	{
		id: 4,
		type: 4,
		color: '#6bb51e',
		points: 500,
		correct: null,
		group: 'Pszichológiai manipuláció',
		title: 'Öt különböző SZAKASZ – Melyik nem tartozik a pszichológiai manipuláció jellemző módszerei közé?',
		text: '<ol><li>Kapcsolatfelvétel, kapcsolatépítés</li><li>Információgyűjtés</li><li>Állandó telefonos elérhetőség biztosítása a célszemély számára</li><li>Információk továbbítása</li><li>Visszavonulás</li></ol><p>Ismerd fel a négy szakaszt, és állítsd őket a helyes sorrendbe.</p>',
		options: [
			'Sorrend<br />1, 2, 3, 5',
			'Sorrend<br />2, 1, 4, 5',
			'Sorrend<br />1, 2, 4, 5',
		],
		correctOptionIndex: 1,
		takeAway: {
			headline: 'Célzott adathalászat (spear phishing) esetén konkrétan kiválasztják az áldozatot, és csak őt támadják meg',
			list: [
				'Az ehhez szükséges információkat a pszichológiai manipuláció első szakaszában szerzik meg a támadók nyilvános forrásokban kutatva.',
				'2. szakasz: Előkészítésként az összegyűjtött információk felhasználásával kapcsolatot építenek ki a célszemélyhez.',
				'3. szakasz: Most a kapcsolatot és az információkat felhasználva keresnek egy ígéretes támadási pontot.',
				'4. szakasz: A támadók eltüntetik a nyomokat, amelyek alapján kitalálható lenne a személyazonosságuk. A pszichológiai manipuláció során hihetőnek tűnő indokot adnak a kapcsolat megszakítására, így nem keltenek gyanút a célszemélyekben.',
			],
		},
	},
	{
		id: 5,
		type: 3,
		color: '#00a1e5',
		points: 300,
		correct: null,
		group: 'Pszichológiai manipuláció',
		title: '„Igaz, vagy hamis? A pszichológiai manipuláció az úgynevezett Business E-Mail Compromise (üzleti e-mailekkel kapcsolatos visszaélés, BEC) egyik sikertényezője is.”',
		text: '„Ennek során a csalók olyan, ügyesen hamisított e-maileket küldenek a kiválasztott személyeknek, amelyek látszólag a vállalat egyik vezetőjétől vagy üzleti partnerektől származnak. A célszemélyeket rá kell venniük a pénz a csaláshoz használt számlára való átutalására.” Igaz ez, vagy sem?',
		correctAnswer: true,
		case: {
			headline: 'A norvég vagyonalap, a Norfund 10 millió dollárt veszített a Business E-Mail Compromise (BEC) következtében',
			whatHappened: [
				'A Norfund az állami költségvetésből kapja befektetési tőkéjét, és a világ egyik legnagyobb állami alapja.',
				'Az alap közzétette, hogy pszichológiai manipuláció/BEC (Business Email Compromise, vagyis e-mailekkel kapcsolatos visszaélés) miatt 10 millió USD-s kára keletkezett. ',
				'Manipulált adatok és hamisított információk keverékének segítségével a csalók hitelintézménynek adták ki magukat, és az igazi címzettek helyett saját zsebükbe irányították az átutalásokat. ',
				'A hackerek „sikerének” kulcsa az volt, hogy sikerült átverniük egy vezető pozícióban dolgozó alkalmazottat. Végül ez a személy gondoskodott arról, hogy a pénz a hackerek által kezelt egyik bankszámlára érkezzen.',
			],
		},
		takeAway: {
			headline: 'Bizalomépítés a siker kulcsaként.',
			list: [
				'BEC esetén az átutalást elvégző alkalmazottat további, közelgő határidő miatti nyomás alá is helyezik, és az utalás titokban tartására utasítják. Ennél a norvégiai esetnél azonban a tettesek tudatosan türelmesek voltak, hogy kiépítsék a bizalmi kapcsolatot, és követték az aprólékosan kidolgozott, hosszú távú céljaikat.',
				'Pszichológiai manipuláció esetén a támadók a kapcsolatfelvétel előtt online kutatva információkat gyűjtenek rólad és a körülötted dolgozókról. Ezért sem közösségimédia-platformokon, sem pedig álláskereső portálokon ne adj ki bizalmas, privát vagy üzleti információkat ismeretlenek számára vagy akár nyilvánosan. ',
			],
		},
	},
	{
		id: 6,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Malware',
		title: 'Hogyan értékeled ezt az e-mailt, és mit teszel?',
		text: '<p>„A Norfund eset lehetséges kiindulópontjában vagyunk. Tegyük fel, hogy te vagy az alkalmazott, akit a kiberbűnöző célszemélyként azonosított.”</p><p>„Tehát a gép előtt ülsz, stresszes helyzetben, és hirtelen kiemelten sürgős e-mail érkezik az egyik munkatársadtól. Mint szinte mindig, most is gyorsan kell minden. Nézzük meg közelebbről…”</p><p>Két dolog feltűnő az e-mailről készült képen:</p><ol><li>Nem UNIPER-es e-mail-címről van szó</li><li>A mellékletben egy PPTX-fájl található.</li></ol>',
		options: [
			'Semmi különöst nem látok, úgy tűnik, minden rendben. Az e-mail úgy néz ki, mint egy UNIPER-levél, és a PPTX formátumú melléklet nem kelt bennem gyanút.',
			'A feladó e-mail-címe nem a UNIPER-hez tartozik, de mostanában sokan otthonról dolgoznak. Ezért előfordulhat, hogy az egyik kolléga privát e-mail-címét használja a levelezéshez.',
			'A feladó e-mail-címét nem tartom megbízhatónak, és a PowerPoint-mellékletek potenciális veszélyforrások.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Légy szkeptikus, amikor arra kérnek, hogy azonnal intézz el valamit.',
			list: [
				'Ne hagyd, hogy nyomás alá helyezzenek – főleg akkor ne, ha következményekkel fenyegetnek.',
				'Hagyj időt a tények tisztázására!',
			],
		},
	},
	{
		id: 7,
		type: 2,
		color: '#f58f00',
		points: 1000,
		correct: null,
		group: 'Phishing',
		title: 'Melyik az erősebb jelszó?',
		text: 'Minden esetben érvényes, hogy a lehető legnehezebbé kell tenni a bűnözők dolgát, hogy ne tudjanak hozzáférni a számítógéphez, a hálózathoz és a rendszerekhez. Ez így van a Norfund esetében, valamint nálunk, a UNIPER-nél is. Az erős jelszó még mindig a legfontosabb biztonsági intézkedések egyike. Egyszerű kérdés: Melyik az erősebb jelszó?',
		options: [
			'Most2pizzátrendelek@NEKEDazétteremből!',
			'P04?§%\\wZ8@',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Erős jelszót használj, és ne oszd meg senkivel.',
			list: [
				'Tartalmazzon legalább 12 karaktert (betűt, számot és speciális karaktert), és ne legyen megtalálható a szótárban. ',
				'Alternatívaként szavakat vagy mondatokat is átalakíthatsz (például: 2_SuPpentellA“). ',
				'Egy jelszókezelő segíthet a biztonságos jelszavak létrehozásában és megjegyzésében is. Az Uniper a SNOW szoftverboltjában ingyenesen tudja beszerezni a KeePasst.',
			],
		},
	},
	{
		id: 8,
		type: 4,
		color: '#d3c002',
		points: 1000,
		correct: null,
		group: 'Pszichológiai manipuláció',
		title: '„Figyelni kell az érzelmekre is!”',
		text: 'Az igazán különleges a „Norfund” esetében az, hogy a támadók hónapokon át tartották a kapcsolatot, és az első sikeres átutalást követően másodszor is nekifutottak, hogy még több pénzt szerezzenek. Itt a pszichológiai manipuláció egyik nagy témája kerül előtérbe, amelynek köszönhetően annyira sikeres lehet ez a csalási forma. Mit keresünk?',
		options: [
			'Érzelem',
			'Akaraterő',
			'Koncentráció',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Vigyázz, az érzelmeidet támadják!',
			list: [
				'Teljesen mindegy, hogy e-mailben, telefonon, SMS-ben, a Messengeren vagy valamilyen közösségimédia-bejegyzésben: Legyél szkeptikus, ha valamilyen üzenet másodperceken belül megmozgat érzelmileg, és azonnali cselekvésre kér.',
			],
		},
	},
	{
		id: 9,
		type: 2,
		color: '#8cd4f4',
		points: 1000,
		correct: null,
		group: 'Malware',
		title: 'Mit értünk „drive by letöltés” alatt?',
		text: 'Az érzelmek döntő szerepet játszanak a pszichológiai manipuláció során – például tudatosan rá kell venni valakit, hogy rákattintson egy adott linkre…',
		options: [
			'A „drive by letöltés” szoftverek nem tudatos és nem szándékos, számítógépre való letöltését jelenti – a folyamat során anélkül kerül sor a fájl letöltésére, hogy a felhasználó észrevenné.',
			'Amikor egy aktív letöltés során (pl. film- vagy zenefájlok letöltésére régóta használt portálokról) tudatosan becsempésznek kártékony programokat is.',
			'Ez a letöltés szerencsére valami jó – egyszerűen egy hatékony antivírusszoftver.',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Alapvető, hogy ne kattints gondolkodás nélkül!',
			list: [
				'Az alábbiak a magán- és hivatalos célra használt eszközökre egyaránt vonatkoznak:',
				'Ne kattints gyanús linkekre – mindig figyelj arra, hogy megbízható platformokat használj.',
				'A biztonság érdekében mindig a böngésző és a bővítmények (a böngésző funkcióit bővítő segédprogramok) legfrissebb verzióját használd.',
			],
		},
	},
	{
		id: 10,
		type: 2,
		color: '#f58f00',
		points: 300,
		correct: null,
		group: 'Otthoni munkavégzés',
		title: 'E három csoport közül melyiknek áll kiemelt érdekében a „korlátlan hozzáférés”?',
		text: 'A helyzet egy kicsit hasonlít az „otthoni munka“ jelentette szituációhoz – nem a központi vállalatot támadták meg, hanem lényegében a „külső egységeket”. Ez az állapot pedig egész egyszerűen különleges figyelmet igényel…',
		options: [
			'Hacker/bűnöző',
			'Politikai aktivista',
			'Felháborodott korábbi alkalmazott',
			'Mindhárom',
		],
		correctOptionIndex: 3,
		case: {
			headline: 'Adatkezelési baleset a Marriott szállodaláncnál',
			whatHappened: [
				'Nagyon rövid időn belül a második adatszivárgás történt ennél a vállalatnál.',
				'5,2 millió vendég volt érintett.',
				'Nyilvánvalóvá vált, hogy a szállodalánc egyik franchise-vevőjének két munkatársa jelentette az adattolvajok számára a bejutási lehetőséget.',
				'A támadók feltehetőleg hozzáférést szereztek az érintett alkalmazottak bejelentkezési adataihoz.',
				'A résen keresztül kiszivárogtak a szálloda vendégeinek bizalmas adatai, többek között igazolványszámok és fizetési adatok is.',
			],
		},
		takeAway: {
			headline: 'Különböző tettesek, különböző érdekek',
			list: [
				'Mindegyik felsorolt csoportnak lényegében az a célja, hogy megkárosítsa a UNIPER-t vagy az ügyfeleinket:<ol><li>Ipari kémek > Belső vállalati adatok továbbadása külső kormányok vagy versenytársak számára</li><li>Bűnözők > Értéktárgyak, okostelefonok és táblagépek ellopása</li><li>Politikai aktivisták > Közérdeklődés felkeltése, tulajdon rombolása</li><li>Terroristák > Erőszakos cselekedetek intézményekben (leányvállalatokban) és emberekkel szemben</li><li>Irigykedő ügyfelek és versenytársak > Információ és figyelem megszerzése, hogy a saját érdekeiket érvényesíthessék</li><li>Felháborodott korábbi alkalmazott > Bizalmas adatok célzott ellopása a vállalat megkárosítása érdekében</li></ol>',
			],
		},
	},
	{
		id: 11,
		type: 2,
		color: '#d3c002',
		points: 300,
		correct: null,
		group: 'Malware',
		title: 'Mik a malware szinonimái, és mit is jelent ez a kifejezés?',
		text: 'Nyilvánvaló, hogy a malware sosem kívánatos dolog, hanem bűnözők kifinomult támadásain keresztül szerez lényegében „jogosulatlan hozzáférést”. Mik a malware szinonimái, és mit is jelent ez a kifejezés magyarul?',
		options: ['Kártékony program', 'Kártékony szoftver', 'A kártékony szoftver, a kártékony program és a malware ugyanazt jelenti'],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'A kártékony szoftver, a kártékony program és a malware szinonimái egymásnak.',
			list: [
				'A kártékony szoftver, a kártékony program és a malware szinonimái egymásnak, és számos olyan különböző számítógépes program gyűjtőfogalmai, amelyek célja, hogy a támadók jogosulatlanul behatoljanak számítógépekbe és hálózatokba, hogy idegen fájlokhoz és adatokhoz férhessenek hozzá.<br /><br />A legfontosabb jellemzőik:<ol><li>Kihasználják a gyenge pontokat a saját érdekek érvényesítése érdekében.</li><li>A programkódot álcázzák vagy folyamatosan módosítják, hogy kicselezzék a műszaki védelmi rendszereket.</li><li>A fejlesztés és az alkalmazás általában munkamegosztáson alapul, vagyis a darkneten való hozzáférhetőség hatalmas mértékben megsokszorozza a fenyegetés mértékét.</li></ol>',
			],
		},
	},
	{
		id: 12,
		type: 2,
		color: '#047fc7',
		points: 1000,
		correct: null,
		group: 'Otthoni munkavégzés',
		title: 'Mit nem jelent az információ osztályozása?',
		text: 'A Marriott elleni második támadás megmutatta, hogy mennyire fontos a saját adatok védelme – nemcsak az irodában, hanem az otthoni munkavégzés esetén is. Az információ osztályozása segít a saját adatok védelmében.',
		options: [
			'A telefon- vagy videokonferencia-szolgáltató szabad választása',
			'Az információvédelem szükségességének saját értékelése és osztályozási szinthez való hozzárendelése',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Az információ osztályozása mint fontos alapelv',
			list: [
				'Lényegében: A UNIPER vállalati szinten bevezette az információk osztályozásának elvét, hogy biztosítani lehessen az információk védelmét, valamint csökkenteni lehessen az adatvesztés veszélyét.',
				'Ha információt hozol létre, módosítasz, külső forrásból mentesz vagy továbbítasz, a következőket kell figyelembe venned: A te felelősséged a védelem szükségességének értékelése.',
				'Az információ osztályozásának elve az otthonról intézett üzleti telefonbeszélgetésekre és videokonferenciákra is vonatkozik. Bizalmasan kezeld ezeket a beszélgetéseket, amennyiben üzleti információkról van szó.',
				'Az Unipernél a video- és telefonkonferenciákhoz alapesetben a Teamset használjuk. Ha alternatívákra van szükség, fordulj a felelős információbiztonsági tisztviselőhöz (ISO).',
			],
		},
	},
]
