





















import Vue from 'vue'
import ModeratorCongrats from './ModeratorCongrats.vue'
import { ResultScreenTexts } from '@/data/Questions'

export default Vue.extend({
	name: 'Result',
	components: { ModeratorCongrats },
	props: {
		points: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			resultScreenTexts: ResultScreenTexts,
		}
	},
	methods: {
		reload() {
			window.location.reload()
		},
	},
	mounted() {
		localStorage.removeItem('gameState')
	},
})
