/* eslint-disable max-len */

export default [
	{
		id: 1,
		type: 1,
		color: '#047fc7',
		points: 300,
		correct: null,
		group: 'Phishing',
		title: '”OM JAG VILL LEDA NÅGON VILSE, SÅ GÖR JAG DET!”',
		options: ['stjäla', 'vilseleda', 'dölja'],
		correctOptionIndex: 1,
		case: {
			headline: 'Ransomware-attack mot KME',
			whatHappened: [
				'Den tyska koppartillverkaren KME utsattes 2020 för en ransomware-attack.',
				'En krypteringstrojan avsedd för industrianläggningar introducerades, som fick delar av tillverknings-processen att stå stilla.',
				'Hackaren ställde ett krav på en lösensumma i miljonklassen.',
				'Sannolika orsaker: Förvärvade inloggningsuppgifter för företagsnätverket från Darknet plus ett osä-kert lösenord för en av administratörerna.',
			],
		},
		takeAway: {
			headline: 'Att vilseleda är ett centralt kännetecken hos angreppstekniker som cyberkriminella använder för att sprida skadlig programvara',
			list: [
				'Angriparen agerar huvudsakligen per e-post och vilseleder mottagaren med en händelse som verkar re-levant.',
				'Innan du öppnar ett e-postmeddelande ska du ställa dig dessa frågor: <ul><li>Förväntar jag mig verkligen detta e-postmeddelande, är det rimligt, kommer det från en seriös av-sändare?</li><li>Är e-postadressen verkligen ”äkta” och hör den till en avsändare jag känner till?</li></ul>',
				'Öppna aldrig bilagor och klicka aldrig på länkar utan att ha kontrollerat e-postadressen: <ul><li>Var försiktig: Spoofing! Bedragare förfalskar ofta en e-postadress du känner till.</li><li>Klicka på avsändarens namn för att se den faktiska e-postadressen.</li></ul>',
			],
		},
	},
	{
		id: 2,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Arbeta hemifrån',
		title: 'I vilken situation är information från UNIPER utsatt för förhöjd risk vid arbete hemi-från?',
		options: [
			'Frågeställningen är helt enkelt fel, eftersom faran för ett lyckat angrepp alltid är lika hög.',
			'Vid åtkomst till en privat enhet via säker VPN-linje.',
			'När jag olovligen skickar jobbinformation till min privata e-postadress.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Skicka aldrig jobbinformation till din privata e-postadress.',
			list: [
				// 'Für den sicheren Zugriff auf die Systeme vpn nutzen.',
				'Rekommendationer för säkerhet hemma:<ul><li>Aktivera WPA2 - krypteringen för trådlöst internet.</li><li>Använd ett starkt lösenord med mer än 20 tecken för det trådlösa nätverket.</li><li>Uppdatera regelbundet operativsystem och virusskydd på privata enheter.</li></ul>',
				'Vad är fysiskt viktigt hemma:<ul><li>Att söka upp en så skyddad plats som möjligt, så att inga främlingar kan få insyn eller höra vad som sägs.</li></ul>',
			],
		},
	},
	{
		id: 3,
		type: 2,
		color: '#01b0e8',
		points: 500,
		correct: null,
		group: 'Phishing',
		title: 'Vilka av dessa filtyper används oftast av cyberkriminella för att sprida skadlig pro-gramvara?',
		options: [
			'PDF-filer',
			'MP4-filer',
			'Word-, Powerpoint- och Excel-filer',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Var särskilt försiktig om du blir ombedd att aktivera makron när du öppnar en fil!',
			list: [
				'Öppna aldrig sådana filer utan att ha försäkrat dig om ett seriöst ursprung för dessa filer genom att prata med avsändaren!',
				'För att infektera främmande enheter och nätverk använder gärningsmannen oftast e-post, alltmer dock även inlägg på sociala media, dessutom via Messenger och textmeddelanden (SMS).',
			],
		},
	},
	{
		id: 4,
		type: 4,
		color: '#6bb51e',
		points: 500,
		correct: null,
		group: 'Social Engineering',
		title: 'Fem olika FASER – vilken hör inte till typiskt förfarande för en social engineer?',
		text: '<ol><li>Ta kontakt för att bygga en relation</li><li>Samla information</li><li>Vara ständigt nåbar på telefon för målpersonen</li><li>Vidarebefordra information</li><li>Dra sig tillbaka</li></ol><p>Nämn de fyra faserna och ange dem i rätt ordning.</p>',
		options: [
			'Ordning<br />1, 2, 3, 5',
			'Ordning<br />2, 1, 4, 5',
			'Ordning<br />1, 2, 4, 5',
		],
		correctOptionIndex: 1,
		takeAway: {
			headline: 'Vid Spear Phishing blir mottagaren särskilt utvald och individuellt angripen',
			list: [
				'Den information som behövs för detta skaffar gärningsmannen i första fasen av manipulation genom ef-terforskning i offentliga källor.',
				'Fas 2: I förväg bygger denne tack vare den insamlade informationen en relation till målpersonen.',
				'Fas 3: Efter att ha utvärderat relationen och informationen hittas sen en lovande angreppspunkt.',
				'Fas 4: Gärningsmannen suddar ut spåren, så att dennes identitet inte förmedlas. Social engineers ger en vad det verkar rimlig orsak till avbrottet i kontakten, så att målpersonen inte blir misstänksam.',
			],
		},
	},
	{
		id: 5,
		type: 3,
		color: '#00a1e5',
		points: 300,
		correct: null,
		group: 'Social Engineering',
		title: '”Social Engineering är också en framgångsfaktor för så kallad Business E-mail Com-promise”, sant eller falskt?',
		text: 'För detta skickar bedragare skickligt förfalskade e-postmeddelanden som verkar komma från en medlem av företagsledningen eller någon affärspartner till utvalda personer. De ska ledas till att överföra pengar till ett bedrägligt konto. Är det sant eller falskt? ',
		correctAnswer: true,
		case: {
			headline: 'Den norska förmögenhetsfonden Norfund förlorar 10 miljoner dollar genom Business E-Mail Compromise (BEC)',
			whatHappened: [
				'Norfund får sitt investeringskapital från statsbudgeten och är den största statliga fonden i världen.',
				'Fonden uppgav att Social Engineering/BEC lett till en förlust om 10 miljoner amerikanska dollar. ',
				'Med hjälp av en blandning av manipulerade data och förfalskad information lyckades bedragaren utge sig för att vara ett kreditinstitut och avleda medel från den riktiga mottagaren till sin egen ficka. ',
				'En nyckel till hackarens ”framgång” var att en chef kunde luras. Denna person ordnade så småningom så att pengarna överfördes till ett konto som kontrollerades av hackaren.',
			],
		},
		takeAway: {
			headline: 'Bygga förtroende som nyckel till framgång.',
			list: [
				'Vid Business E-Mail Compromise blir den ledande medarbetaren satt under tidspress och anvi-sas att hålla överföringen hemlig. I föreliggande fall från Norge visade gärningsmannen med-vetet tålamod, för att bygga förtroende och noggrant lyckas med sitt långfristiga mål.',
				'Social Engineers spionerar på dig och din arbetsmiljö innan de kontaktar dig online. Därför ska du aldrig ange vare sig känslig privat information eller jobbinformation till obekanta personer via sociala mediaplattformar eller i jobbportaler. ',
			],
		},
	},
	{
		id: 6,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Malware',
		title: 'Hur värderar du detta e-postmeddelande och vad gör du?',
		text: '<p>Låt oss se på en möjlig början till Norfund-fallet. Låt oss anta att du är medarbetaren som den cyberkrimi-nelle har identifierat som målperson.</p><p>Du sitter alltså vid datorn, det är stressigt och plötsligt kommer ett e-postmeddelande från en arbetskol-lega av brådskande karaktär. Som så ofta måste det gå snabbt. Titta närmare ...</p><p>Två saker är märkbara i den grafik som visas i mejlet:</p><ol><li>Det rör sig inte om en e-postadress från UNIPER</li><li>Som bilaga finns en .PPTX-fil.</li></ol>',
		options: [
			'Inget särskilt, allt ser ut att vara ok. E-postmeddelandet ser ut som ett mejl från UNIPER och PPTX-filen som bilaga verkar inte misstänkt.',
			'Avsändarens e-postadress är inte från UNIPER. Men många kollegor arbetar just nu hemifrån. Då kan det hända att en kollega mejlar från sin privata adress.',
			'Jag skulle inte lita på avsändarens e-postadress och PowerPoint-bilagor är potentiella källor till fara.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Var skeptisk om du uppmanas att agera omedelbart.',
			list: [
				'Fall inte för trycket – särskilt inte vid hot om konsekvenser.',
				'Ta dig tid att ta reda på fakta!',
			],
		},
	},
	{
		id: 7,
		type: 2,
		color: '#f58f00',
		points: 1000,
		correct: null,
		group: 'Phishing',
		title: 'Vilket är det starkare lösenordet?',
		text: 'Det gäller alltid att göra det så svårt som möjligt för de kriminella, så att åtkomst till datorn, nätverket eller sy-stemet inte är möjlig. Det gäller för Norfund, men också för oss på UNIPER. Ett starkt lösenord är alltid en av de viktigaste försiktighetsåtgärderna. Enkel fråga: Vilket är det starkare lösenordet?',
		options: [
			'Iordermypizza@therestaurant4YOUnow!',
			'P04?§%\\wZ8@',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Använd ett starkt lösenord, som du inte delar med någon.',
			list: [
				'Det ska bestå av minst tolv tecken (bokstäver, siffror och specialtecken) och inte komma från ordboken.',
				'Alternativt kan du förvränga ord eller meningar (exempel: 2_SuPpentellA“).',
				'En lösenordshanterare hjälper till att skapa och också att memorera säkra lösenord. På Uniper kan vi på SNOW i mjukvarubutiken skaffa gratis KeePass.',
			],
		},
	},
	{
		id: 8,
		type: 4,
		color: '#d3c002',
		points: 1000,
		correct: null,
		group: 'Social Engineering',
		title: '”Det har med känsla att göra!”',
		text: 'Det mycket speciella med fallet ”Norfund” är det faktum att gärningsmannen höll kontakten över flera månader och även efter den första lyckade överföringen gjorde ett andra försök att komma över mer pengar. Här står ett starkt tema för Social Engineering i förgrunden, som gör denna form av bedrägeri så framgångsrik. Vad letar man efter?',
		options: [
			'Känsla',
			'Viljestyrka',
			'Koncentration',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Försiktigt, attacker på dina känslor!',
			list: [
				'Oavsett om det kommer per e-post, via telefon, via textmeddelande (SMS), Messenger eller inlägg på sociala media: Var skeptiskt så fort ett meddelande rör dig känslomässigt inom några sekunder och uppmanar dig att agera omedelbart.',
			],
		},
	},
	{
		id: 9,
		type: 2,
		color: '#8cd4f4',
		points: 1000,
		correct: null,
		group: 'Malware',
		title: 'Vad är ”Drive-by-Download”?',
		text: 'Känslor spelar en avgörande roll för temat Social Engineering – till exempel ska man vara medveten innan man klickar på en viss länk ...',
		options: [
			'En Drive-by-Download är omedveten och oavsiktlig nedladdning av programvara på en dator – i ”förbi-farten” laddas en fil ner utan aktiv kännedom hos användaren.',
			'Vid aktiv nedladdning av t.ex. filmfiler eller musikfiler från en etablerad app-portal introduceras ett medvetet skadligt program.',
			'Denna nedladdning är lyckligtvis något bra – det är helt enkelt ett effektivt virusprogram.',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Klicka aldrig oöverlagt!',
			list: [
				'Såväl vid användning av privata- och jobbdatorer gäller:',
				'Klicka aldrig på misstänkta länkar – var alltid uppmärksam på pålitliga plattformar.',
				'Som skydd ska du alltid ha den senaste versionen av webbläsaren samt använda alla plugins (hjälpprogram som utökar funktionaliteten i webbläsaren).',
			],
		},
	},
	{
		id: 10,
		type: 2,
		color: '#f58f00',
		points: 300,
		correct: null,
		group: 'Arbeta hemifrån',
		title: 'Vilken av dessa tre persongrupper har ett ökat intresse av ”Obehörig åtkomst”?',
		text: 'Situationen är lite lik den med ”Arbeta hemifrån” – att inte angripa huvudföretaget, utan ”externa enheter”. Och detta faktum kräver helt enkelt särskild uppmärksamhet ...',
		options: [
			'Hackare/kriminella',
			'Politisk aktivist',
			'Arg före detta medarbetare',
			'alla',
		],
		correctOptionIndex: 3,
		case: {
			headline: 'Dataintrång hos hotellkedjan Marriott',
			whatHappened: [
				'Två dataintrång inom kort tid för detta företag',
				'5,2 miljoner gäster drabbas',
				'Två medarbetare hos en franchisetagare i hotellkedjan var tydligen porten in för datatjuvarna',
				'Antagligen skaffade sig angriparen åtkomst till inloggningsuppgifterna för nämnda medarbetare',
				'I och med läckan flödade känsliga uppgifter om hotellkunderna ut, bland annat ID-nummer och betal-ningsinformation',
			],
		},
		takeAway: {
			headline: 'Olika gärningsmän, olika intressen',
			list: [
				'För alla dessa persongrupper gäller i regel att de vill skada UNIPER eller våra kunder:<ol><li>Industriella spioner > Avslöjande av företagsintern information till främmande regeringar eller konkur-renter</li><li>Kriminella > Stöld av värdesaker, smartphones och plattor</li><li>Politiska aktivister > Väcka allmänhetens intresse, förstöra egendom</li><li>Terrorister > Våldsdåd på institutioner (filialer) och mot personer</li><li>Missnöjda kunder och konkurrenter > Erhållande av information och uppmärksamhet för att verkställa egna intressen</li><li>Arga före detta medarbetare > Målinriktad stöld av känslig information för att orsaka företaget skada</li><ol>',
			],
		},
	},
	{
		id: 11,
		type: 2,
		color: '#d3c002',
		points: 300,
		correct: null,
		group: 'Malware',
		title: 'Vilken är egentligen synonymen till malware, vad menas med det?',
		text: 'Malware är helt klart aldrig önskad, utan skaffar sig genom sofistikerade angrepp från kriminella ”obehörig åtkomst”. Vilken är egentligen synonymen till malware, vad menas med det?',
		options: ['Skadligt program', 'Skadlig programvara', 'Skadlig programvara, skadligt program och malware är identiska begrepp'],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Begreppen skadlig programvara, skadligt program eller malware är synonymer.',
			list: [
				'Begreppen skadlig programvara, skadligt program eller malware är synonymer eller generiska termer för flera olika datorprogram som tjänar till obehörigt inträde i datorer och nätverk för att få tillgång till tredjepartsdata och information.<br /><br />Väsentliga egenskaper:<ol><li>Utnyttjande av svaga punkter för att verkställa egna intressen</li><li>Programkoden maskeras eller ändras fortlöpande för att kringgå tekniska skyddssystem</li><li>Utveckling och användning sker i en arbetsfördelning, dvs. tillgängligheten på Darknet multiplicerar ho-tet oerhört</li></ol>',
			],
		},
	},
	{
		id: 12,
		type: 2,
		color: '#047fc7',
		points: 1000,
		correct: null,
		group: 'Arbeta hemifrån',
		title: 'Vad betyder informationsklassificering inte?',
		text: 'Det andra angreppet mot Marriott visade hur viktigt skyddet av egen information är - på kontoret men också om man arbetar hemifrån. Informationsklassificering hjälper till att skydda egna uppgifter.',
		options: [
			'Fritt val av telefon- eller videokonferensleverantör',
			'Egen bedömning av sårbarheten hos informationen och indelning i en klassificeringsnivå',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Informationsklassificering som viktig princip',
			list: [
				'I grund och botten: UNIPER har infört en koncernomfattande princip om klassificering av information, för att garantera skyddet av information och minska risken för dataförluster.',
				'Så snart du skapar, ändrar eller lagrar information på eller vidarebefordrar den till en extern källa gäl-ler: Det är ditt ansvar att bedöma sårbarheten.',
				'Principen om informationsklassificering gäller även för jobbsamtal och videokonferenser som du ge-nomför hemma. Behandla dessa samtal konfidentiella, så snart det rör sig om affärsinformation.',
				'Hos Uniper använder vi Teams för video- och telefonkonferenser. För nödvändiga alternativ kan du kontakta din närmsta Information Security Officer (ISO).',
			],
		},
	},
]
