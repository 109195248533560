var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.question.title))]),(_vm.question.id === 6)?_c('img',{staticClass:"image-preview",attrs:{"src":_vm.$i18n.locale === 'de'
			? require('@/assets/img/email-de.jpg')
			: require('@/assets/img/email-en.jpg'),"alt":"E-Mail Screenshot"},on:{"click":function($event){_vm.showLightbox = true}}}):(_vm.question.text)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.question.text)}}):_vm._e(),_c('div',{staticClass:"selection"},_vm._l((_vm.question.options),function(option,index){return _c('div',{key:index,staticClass:"option",class:[("option-" + index), {
				disabled: _vm.correct && _vm.selected !== index,
				active: _vm.selected === index,
			}],on:{"click":function($event){return _vm.select(index)}}},[_c('label',[_vm._v(_vm._s(index === 0 ? 'a' : (index === 1 ? 'b' : (index === 2 ? 'c' : 'd')))+")")]),_c('p',{domProps:{"innerHTML":_vm._s(option)}}),(_vm.selected === index && _vm.question.correctOptionIndex === index)?_c('correct-svg',{staticClass:"overlay",attrs:{"points":_vm.question.points}}):(_vm.selected === index && _vm.question.correctOptionIndex !== index)?_c('img',{staticClass:"overlay",attrs:{"src":require("@/assets/img/incorrect.svg")}}):_vm._e()],1)}),0),(_vm.showLightbox)?_c('div',{staticClass:"image-lightbox",on:{"click":function($event){_vm.showLightbox = false}}},[_c('img',{attrs:{"src":_vm.$i18n.locale === 'de'
				? require('@/assets/img/email-de.jpg')
				: require('@/assets/img/email-en.jpg'),"alt":"E-Mail Screenshot"}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }