






























import Vue from 'vue'

export default Vue.extend({
	name: 'Info',
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
})
