/* eslint-disable max-len */

export default [
	{
		id: 1,
		type: 1,
		color: '#047fc7',
		points: 300,
		correct: null,
		group: 'Phishing',
		title: '“If I want to fool somebody, that’s what I do!”',
		options: ['steal', 'deceive', 'hide'],
		correctOptionIndex: 1,
		case: {
			headline: 'Ransomware attack on KME',
			whatHappened: [
				'German copper manufacturer KME fell victim to a ransomware attack in 2020.',
				'An encryption Trojan designed to attack industrial systems successfully shut down parts of the production process.',
				'The hackers demanded a ransom of over one million euros.',
				'Most probable causes: log-in data for the company network acquired from the dark net, plus a weak administrator password.',
			],
		},
		takeAway: {
			headline: 'Deception features heavily in the tactics used by cyber-criminals to spread malware',
			list: [
				'The hackers usually attack via e-mail, using a seemingly relevant pretext to deceive recipients.',
				'Always ask yourself these questions before opening an e-mail:<br /><ul><li>Am I really expecting this e-mail, is it plausible, does it come from a legitimate sender?</li><li>Is the e-mail address really genuine and does it belong to the sender I know?</li></ul>',
				'Never open attachments or click on links without having checked the e-mail address:<ul><li>Beware of spoofing! Often, phishing e-mails appear to come from a familiar e-mail address.</li><li>Click on the sender’s name to see the actual e-mail address.</li></ul>',
			],
		},
	},
	{
		id: 2,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Working from home',
		title: 'In what situation is UNIPER information exposed to increased risk when you are working from home?',
		options: [
			'The question has to be put differently, because the danger of an attack succeeding is always the same.',
			'When accessing a private device via a secure VPN.',
			'When I send work-related information to my private e-mail account without permission.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Never send work-related information to your private e-mail address.',
			list: [
				// 'Für den sicheren Zugriff auf die Systeme vpn nutzen.',
				'Recommendations to boost security at home:<br />> Activate the WiFi’s WPA2 encryption.<br />> Use a strong password with more than 20 characters for the WiFi.<br />> On private devices, regularly update the operating system and virus protection programs.',
				'These physical measures are important at home:<br />> Use a workplace that is as well protected as possible and does not allow strangers to see or listen in.',
			],
		},
	},
	{
		id: 3,
		type: 2,
		color: '#01b0e8',
		points: 500,
		correct: null,
		group: 'Phishing',
		title: 'Which of these file types are most commonly used by cyber-criminals to distribute malware?',
		options: [
			'PDF files',
			'MP4 files',
			'Word, PowerPoint and Excel files',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Be especially careful if you are asked to enable macros when opening a file!',
			list: [
				'Never open such files without checking with the sender that the file stems from a reputable source!',
				'The most common way for criminals to infect third-party devices and networks is via e-mail, but increasingly also via social media posts, messaging services and text messages (SMS).',
			],
		},
	},
	{
		id: 4,
		type: 4,
		color: '#6bb51e',
		points: 500,
		correct: null,
		group: 'Social Engineering',
		title: 'Which of these five stages is not typically part of a social engineering attack?',
		text: '<ol><li>Making contact and developing a relationship</li><li>Gathering information</li><li>Always available for the target by phone</li><li>Passing on information</li><li>Retreat</li></ol><p>Put the four correct stages in the right order.</p>',
		options: [
			'Sequence<br />1, 2, 3, 5',
			'Sequence<br />2, 1, 4, 5',
			'Sequence<br />1, 2, 4, 5',
		],
		correctOptionIndex: 1,
		takeAway: {
			headline: 'The recipient of a spear phishing e-mail is selected and targeted specifically',
			list: [
				'In the first stage of a social engineering attack, the crooks get the information they need by researching publicly accessible sources.',
				'Stage 2: In the run-up to the attack, they develop a relationship with the target using the information they collected.',
				'Stage 3: Now they exploit the relationship and use the information gleaned to find a promising point of attack.',
				'Stage 4: The crooks cover their tracks in order to avoid being identified. Social engineers provide a plausible reason for breaking off contact so that the targets do not suspect anything.',
			],
		},
	},
	{
		id: 5,
		type: 3,
		color: '#00a1e5',
		points: 300,
		correct: null,
		group: 'Social Engineering',
		title: 'Social engineering is also a success factor for what is known as Business E-mail Compromise, true or false?',
		text: 'BEC involves scammers sending well-faked e-mails, purporting to come from a member of the company management or business partners, to selected persons in a bid to persuade the latter to transfer money to a fraudulent account. Is this true or false?',
		correctAnswer: true,
		case: {
			headline: 'Norwegian investment fund Norfund loses USD 10 million as a result of Business E-Mail Compromise (BEC)',
			whatHappened: [
				'Norfund receives its investment capital from the state budget and is the largest sovereign wealth fund in the world.',
				'The fund reported a loss of USD 10 million as a result of social engineering/BEC.',
				'Using a mix of manipulated data and falsified information, the scammers managed to pass themselves off as the lending institution and divert funds from the real recipient into their own pockets.',
				'One of the key factors in the hackers’ “success” was that they were able to deceive a senior executive, who eventually arranged for the money to be transferred to an account controlled by the crooks.',
			],
		},
		takeAway: {
			headline: 'Building trust as the key to success.',
			list: [
				'With Business E-mail Compromise, the executing employees are also put under time pressure and advised of the confidentiality of the transfer. In this particular case from Norway, however, the perpetrators deliberately took their time to build trust so that they could pursue their long-term goals to the letter.',
				'Social engineers research you and your working environment online before making contact. So never reveal sensitive private or work-related information to strangers on social media platforms, on job portals or even publicly.',
			],
		},
	},
	{
		id: 6,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Malware',
		title: 'What do you think of this e-mail and how do you respond?',
		text: '<p>Let’s take a look at how the Norfund case could have started. We’ll assume that you are the employee targeted by the cyber-criminals.</p><p>So, you’re sitting stressed in front of the computer when you suddenly receive an extremely urgent e-mail from a colleague. As is so often the case, you are requested to act fast. Take a close look …</p><p>Two things are noticeable in the e-mail:</p><ol><li>It has not been sent from a UNIPER e-mail address</li><li>The attachment contains a .PPTX file.</li></ol>',
		options: [
			'It’s nothing out of the ordinary, everything seems fine. The e-mail looks like a UNIPER e-mail and the PPTX file attachment does not seem suspicious to me.',
			'The sender’s e-mail address is not a UNIPER address. But many colleagues are currently working from home, so one of them may be using his private e-mail address to send e-mails.',
			'I would not trust the sender\'s e-mail address and PowerPoint attachments are potential sources of danger.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Be wary if you are asked to take immediate action.',
			list: [
				'Do not allow yourself to be put under pressure – especially if threatened with consequences.',
				'Take the time needed to establish the facts!',
			],
		},
	},
	{
		id: 7,
		type: 2,
		color: '#f58f00',
		points: 1000,
		correct: null,
		group: 'Phishing',
		title: 'Which is the stronger password?',
		text: 'You should always make it as difficult as possible for crooks to access your computer, network or systems. That applies to us at UNIPER just as it does to Norfund. A strong password is still one of the most important safeguards. So, a simple question: Which is the stronger password?',
		options: [
			'Iordermypizza@therestaurant4YOUnow!',
			'P04?§%\\wZ8@',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Use a strong password and never share it with others. ',
			list: [
				'It should comprise at least twelve characters (letters, numbers and special characters) and not be copied from the dictionary.',
				'You can also distort words or sentences (e.g.: 2_SuPpentellA”).',
				'A password manager can help you to devise and remember secure passwords. At Uniper, KeePass is available free of charge in the software store in SNOW.',
			],
		},
	},
	{
		id: 8,
		type: 4,
		color: '#d3c002',
		points: 1000,
		correct: null,
		group: 'Social Engineering',
		title: '“It has something to do with emotion!”',
		text: 'The really unusual aspect of the Norfund case is that fact that the perpetrators maintained contact over a number of months and made a second attempt to steal more money following the first successful transfer. This highlights a major aspect of social engineering that makes this type of scam so successful. What word are we looking for?',
		options: [
			'Emotion',
			'Willpower',
			'Concentration',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Beware of an attack on your emotions!',
			list: [
				'Whether you are contacted via e-mail, phone, text message, messaging service or social media post, be wary of any communication that immediately seeks to play on your emotions and requests immediate action.',
			],
		},
	},
	{
		id: 9,
		type: 2,
		color: '#8cd4f4',
		points: 1000,
		correct: null,
		group: 'Malware',
		title: 'What is meant by “drive-by download”?',
		text: 'Emotions play a crucial role when it comes to social engineering, with crooks deliberately attempting to get you to click on a specific link, for instance …',
		options: [
			'Drive-by download refers to the unwitting and inadvertent downloading of software onto a computer – a file is downloaded “in passing” without the user’s knowledge.',
			'Malware is deliberately “planted” on users’ devices when they actively download video or music files from established app portals.',
			'Thankfully, this type of download is a positive thing – it is simply effective anti-virus software.',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Avoid careless clicks!',
			list: [
				'The following applies to the use of both personal and business devices:',
				'Never click on suspicious links – always take care to download material from trustworthy platforms.',
				'Take precautions by always using the latest versions of the browser and all plugins (pieces of software code that extend the browser’s functionality).',
			],
		},
	},
	{
		id: 10,
		type: 2,
		color: '#f58f00',
		points: 300,
		correct: null,
		group: 'Working from home',
		title: 'Which of these three groups of people is especially interested in gaining “unauthorised access”?',
		text: 'The situation is similar to that of “working from home”, with the difference that “outlying units” were attacked, rather than the main company itself. And particular vigilance is called for in this situation ...',
		options: [
			'Hackers/crooks',
			'Political activists',
			'Disgruntled former employees',
			'All of the above',
		],
		correctOptionIndex: 3,
		case: {
			headline: 'Data breach at the Marriott hotel chain',
			whatHappened: [
				'Company experiences second data breach in quick succession',
				'5.2 million customers affected',
				'Data thieves gained access to information via two employees of one of the hotel chain’s franchise properties',
				'The hackers are suspected of using these employees’ login credentials',
				'Sensitive Marriott customer data, including passport numbers and payment information, was accessed in the process',
			],
		},
		takeAway: {
			headline: 'Different perpetrators, different interests',
			list: [
				'All of these groups of people are generally seeking to harm UNIPER or our customers. <ol><li>Corporate spies > The passing on of internal company information, for example to foreign governments or competitors</li><li>Criminals > Theft, for example of valuables, smartphones and tablets</li><li>Political activists > Raising of public awareness, destruction of property</li><li>Terrorists > Acts of violence in institutions (branches) and against individuals</li><li>Envious customers and competitors > Obtaining information and attracting attention for personal gain</li><li>Disgruntled former employees > Targeted theft of sensitive data in order to harm the company</li></ol>',
			],
		},
	},
	{
		id: 11,
		type: 2,
		color: '#d3c002',
		points: 300,
		correct: null,
		group: 'Malware',
		title: 'What is a synonym for malware, what does it mean?',
		text: 'It goes without saying that malware is never deliberately downloaded onto systems, but gains “unauthorised access” thanks to the sophisticated machinations of crooks. What is a synonym for malware, what does it really mean?',
		options: ['Malicious program', 'Malicious software', 'Malicious software, malicious program and malware all mean the same thing'],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'The terms malicious software, malicious program and malware are all synonymous.',
			list: [
				'Malicious software and malware are synonymous overarching terms for a wide range of computer programs designed to penetrate devices and networks without authorisation with a view to accessing third-party data and information.<br />Main characteristics: <ol><li>Exploitation of vulnerabilities with a view to pursuing one’s own agenda</li><li>Program code is disguised or continually modified in order to evade recognition by technical security systems</li><li>Development and use generally involve a collaborative effort – availability in the dark net multiplies the threat considerably</li></ol>',
			],
		},
	},
	{
		id: 12,
		type: 2,
		color: '#047fc7',
		points: 1000,
		correct: null,
		group: 'Working from home',
		title: 'What does information classification not entail?',
		text: 'The second attack on the Marriott chain demonstrated the importance of protecting one’s own data – both in the office and when working from home. Information classification helps people to protect their own data.',
		options: [
			'Free choice of telephone or video conference provider',
			'Own assessment of the protection requirements of information and assignment of a classification level',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Information classification as an important principle',
			list: [
				'Basic rules: UNIPER has introduced a group-wide information classification principle in order to ensure that information is protected and to reduce the risk of data loss.',
				'The following applies whenever you create or modify information, store it from an external source or forward it: you are responsible for evaluating its protection requirements.',
				'The principle of information classification also applies to work-related telephone calls and video conferences conducted from home. Respect the confidentiality of these conversations as far as they relate to business matters.',
				'At Uniper, we generally use Teams for video and telephone conferences. Contact your Information Security Officer (ISO) if you need to use an alternative solution.',
			],
		},
	},
]
