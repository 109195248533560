import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const de = require('./lang/de.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const en = require('./lang/en.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const nl = require('./lang/nl.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const hu = require('./lang/hu.json')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const se = require('./lang/se.json')

Vue.config.productionTip = false

Vue.use(VueI18n)

const messages = {
	en,
	de,
	nl,
	hu,
	se,
}

const availableLanguages = ['en', 'de', 'nl', 'hu', 'se']
const browserLanguage = navigator.language.split('-')[0]

const i18n = new VueI18n({
	locale: availableLanguages.includes(browserLanguage) ? browserLanguage : 'en',
	messages,
})

new Vue({
	render: (h) => h(App),
	i18n,
}).$mount('#app')
