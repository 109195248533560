
































import Vue from 'vue'
import CorrectSvg from './CorrectSvg.vue'

export default Vue.extend({
	name: 'QuestionContent4',
	components: { CorrectSvg },
	props: {
		question: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selected: null as number|null,
		}
	},
	computed: {
		correct(): boolean {
			return this.selected === this.question.correctOptionIndex
		},
		incorrect(): boolean {
			return this.selected !== null && this.selected !== this.question.correctOptionIndex
		},
	},
	methods: {
		select(i: number) {
			if (this.selected !== null) return
			this.selected = i
			this.$emit('select', i)
		},
	},
})
