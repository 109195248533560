import { render, staticRenderFns } from "./QuestionContent2.vue?vue&type=template&id=83392bac&scoped=true&"
import script from "./QuestionContent2.vue?vue&type=script&lang=ts&"
export * from "./QuestionContent2.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionContent2.vue?vue&type=style&index=0&id=83392bac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83392bac",
  null
  
)

export default component.exports