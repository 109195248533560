/* eslint-disable max-len */

import de from './questions/de'
import en from './questions/en'
import nl from './questions/nl'
import hu from './questions/hu'
import se from './questions/se'

const Questions: {[language: string]: any[]} = {
	de,
	en,
	nl,
	hu,
	se,
}

export const GridItems = [
	{ color: '#d3c002', points: 300, questionIndex: 10 },
	{ color: '#047fc7', points: 300, questionIndex: 0 },
	{ color: '#00a1e5', points: 300, questionIndex: 4 },
	{ color: '#f58f00', points: 300, questionIndex: 9 },
	{ color: '#015380', points: 500, questionIndex: 5 },
	{ color: '#01b0e8', points: 500, questionIndex: 2 },
	{ color: '#6bb51e', points: 500, questionIndex: 3 },
	{ color: '#015380', points: 500, questionIndex: 1 },
	{ color: '#8cd4f4', points: 1000, questionIndex: 8 },
	{ color: '#f58f00', points: 1000, questionIndex: 6 },
	{ color: '#d3c002', points: 1000, questionIndex: 7 },
	{ color: '#047fc7', points: 1000, questionIndex: 11 },
]

export const ResultScreenTexts: { [language: string]: string } = {
	de: 'Vielen Dank, dass Du Dich mit Wissen rund um das Thema Sicherheit gestärkt hast.<br />Du möchtest an unserem Gewinnspiel vom 01. - 31. Oktober 2021 teilnehmen? Dann mach bitte einen Screenshot von dieser Seite inkl. Deines Punktestandes und schicke ihn mit dem Betreff „Uniper Wissenswand Endergebnis“ an <b>infosec@uniper.energy</b>. Du denkst Du kannst es noch besser machen? Dann kannst Du es über den Button „Nochmal spielen“ gerne erneut versuchen. Die drei Gewinner werden mit einem Amazon Gutschein prämiert. Bei Punktgleichheit entscheidet das Los, deshalb wird natürlich nur eine Einsendung pro Person gestattet. Viel Erfolg.',
	en: 'Thank you for taking the time to boost your security knowledge.<br />If you would like to take part in our competition, which runs from 1 to 31 October 2021, take a screenshot of this page, showing your score, and e-mail it to <b>infosec@uniper.energy</b> under the subject line “Uniper knowledge wall result”. If you think you are capable of achieving a higher score, you are welcome to click on the “Play again” button. The three winners will each be presented with an Amazon voucher. In the event of a tie, lots will be drawn, which is why only one entry per person is permitted. Good luck.',
	nl: 'Bedankt dat je je kennis op het gebied van veiligheid hebt verbreed.<br /> Je doet mee met onze wedstrijd tussen 1 en 31 oktober 2021? Maak dan een screenshot van deze pagina incl. je score en verstuur deze met het onderwerp \'Uniper-kennismuur eindresultaat\' aan <b>infosec@uniper.energy</b>. Denk je dat je je score kunt verbeteren? Dan kun je het met de knop \'Nogmaals spelen\' nog een keer proberen. De drie winnaars ontvangen een tegoedbon van Amazon. In het geval van een gelijke puntenstand wordt er geloot. Daarom is er slechts één inzending per persoon toegestaan. Veel succes.',
	hu: 'Kiértékelés/pontozás stb.<br /><br />Köszönjük, hogy bővítetted a biztonsággal kapcsolatos tudásodat.<br /><br />Szeretnél részt venni a 2021. október 1-31. közötti nyereményjátékunkban? Ezután kérjük, készíts egy képernyőképet erről az oldalról a pontszámoddal együtt és az „Uniper Wissenswand Endergebnis” tárgy megjelölésével küldd el az alábbi címre: <b>infosec@uniper.energy</b>. Úgy gondolod, hogy még jobb teljesítményre is képes vagy? A „Nochmal spielen” (Újra játszom) gombra kattintva újra megpróbálhatod. A három nyertes egy Amazon-utalványt kap. Pontegyenlőség esetén sorsolással döntünk a nyertesről, így természetesen személyenként csak egy nevezés engedélyezett. Sok sikert kívánunk.',
	se: 'Tack så mycket för att du stärkt din kunskap på temat Säkerhet.<br /><br />Vill du delta i vår tävling mellan 1-31 oktober? Ta i så fall en screenshot av denna sida inklusive dina poäng och skicka den med rubriken ”Uniper Kunskapsvägg slutresultat“ till <b>infosec@uniper.energy</b>. Tror du att du kan göra det ännu bättre? Då kan du gärna försöka igen genom att trycka på knappen ”Spela igen”. De tre vinnarna belö-nas med ett presentkort på Amazon. Om det blir lika avgör lotten, så därför är endast ett bidrag per person tillå-tet. Lycka till.',
}

export default Questions
