/* eslint-disable max-len */

export default [
	{
		id: 1,
		type: 1,
		color: '#047fc7',
		points: 300,
		correct: null,
		group: 'Phishing',
		title: '“ALS IK IEMAND OM DE TUIN WIL LEIDEN, DAN DOE IK DAT!”',
		options: ['stelen', 'misleiden', 'verbergen'],
		correctOptionIndex: 1,
		case: {
			headline: 'Ransomware-aanval op KME',
			whatHappened: [
				'De Duitse koperproducent KME is in 2020 slachtoffer geworden van een ransomware-aanval.',
				'In het systeem werd een gijzelsoftware geïnstalleerd die is gespecialiseerd in industriële installaties en die delen van het productieproces stillegde.',
				'De hackers hebben een losgeldsom van in de miljoenen geëist.',
				'Vermoedelijke oorzaken: verkregen logingegevens voor het bedrijfsnetwerk via het dark web in com-binatie met een onveilig wachtwoord van een beheerder.',
			],
		},
		takeAway: {
			headline: 'Misleiding is een typische aanvalstactiek die cybercriminelen gebruiken om schade-lijke software te verspreiden',
			list: [
				'De aanvallers benaderen bedrijven voornamelijk via e-mail en misleiden de ontvangers met aanneme-lijke verhalen.',
				'Stel je voordat je een e-mail opent altijd de volgende vragen:<br /><ul><li>Verwacht ik deze e-mail, is de e-mail plausibel en is deze afkomstig van een betrouwbare afzender?</li><li>Is het e-mailadres wel echt ‘authentiek’ en is dit het adres van de betreffende afzender?</li></ul>',
				'•	Open nooit bijlagen en klik niet op links zonder dat je het e-mailadres hebt gecontroleerd:<ul><li>Pas op: spoofing! Oplichters laten het vaak lijken alsof hun e-mail is verstuurd vanuit een voor jou be-kend e-mailadres:</li><li>Klik op de naam van de afzender om het daadwerkelijke e-mailadres te zien.</li></ul>',
			],
		},
	},
	{
		id: 2,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Thuiswerken',
		title: 'In welke situatie wordt informatie van UNIPER blootgesteld aan een verhoogd risico bij het thuiswerken?',
		options: [
			'De vraag is verkeerd gesteld, want het risico op een geslaagde cyberaanval is altijd even hoog.',
			'Als ik een privé-apparaat en een beveiligde VPN-verbinding gebruik om in het systeem te komen.',
			'Wanneer ik zonder toestemming zakelijke informatie naar mijn persoonlijke e-mailaccount verstuur.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Stuur nooit zakelijke informatie naar een persoonlijk e-mailaccount.',
			list: [
				'Für den sicheren Zugriff auf die Systeme vpn nutzen.',
				'Aanbevelingen voor de veiligheid thuis:<br />> Activeer de WPA2-versleuteling van de wifi.<br />> Gebruik een sterk wifiwachtwoord met meer dan twintig tekens.<br />> Update het besturingssysteem en de antivirussoftware op privé-apparaten regelmatig.',
				'Wat is ruimtelijk gezien van belang als je thuiswerkt:<br />> Werk aan een zo goed mogelijk afgeschermde plek, waar vreemden niet kunnen meekijken of meeluis-teren.',
			],
		},
	},
	{
		id: 3,
		type: 2,
		color: '#01b0e8',
		points: 500,
		correct: null,
		group: 'Phishing',
		title: 'Welke van deze bestandstypen gebruiken cybercriminelen het meest om schadelijke software te verspreiden?',
		options: [
			'pdf-bestanden',
			'mp4-bestanden',
			'Word-, PowerPoint- en Excel-bestanden',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Wees extra op je hoede wanneer je bij het openen van een bestand wordt gevraagd om macro\'s te activeren!',
			list: [
				'Open dergelijke bestanden nooit zonder dat je bij de afzender hebt gecontroleerd of de herkomst van het bestand betrouwbaar is.',
				'De daders vallen meestal via e-mail aan om externe apparaten en netwerken te infecteren. Ze maken echter in toenemende mate gebruik van socialmediaposts of chatprogramma\'s en sms - berichten om aanvallen te lanceren.',
			],
		},
	},
	{
		id: 4,
		type: 4,
		color: '#6bb51e',
		points: 500,
		correct: null,
		group: 'Social Engineering',
		title: 'Vijf verschillende fases: welke fase maakt geen deel uit van de typische werkwijze van een social engineer?',
		text: '<ol><li>contact opnemen, een relatie opbouwen</li><li>informatie verzamelen</li><li>altijd telefonisch bereikbaar zijn voor het doelwit</li><li>informatie doorsturen</li><li>terugtrekken</li></ol><p>Identificeer de vier correcte fases en zet ze in de juiste volgorde.</p>',
		options: [
			'volgorde<br />1, 2, 3, 5',
			'volgorde<br />2, 1, 4, 5',
			'volgorde<br />1, 2, 4, 5',
		],
		correctOptionIndex: 1,
		takeAway: {
			headline: 'Bij spear phishing wordt de ontvanger specifiek geselecteerd en individueel aange-vallen',
			list: [
				'De vereiste informatie hiervoor vergaren de daders in de eerste fase van social engineering door open-bare bronnen te doorzoeken.',
				'Fase 2: de daders gebruiken de verzamelde informatie om voorafgaand aan de aanval een relatie met het doelwit op te bouwen.',
				'Fase 3: in deze fase benutten ze de opgebouwde band en vergaarde informatie om te bepalen waar een veelbelovende zwakke plek ligt.',
				'Fase 4: de daders wissen alle sporen uit die gebruikt zouden kunnen worden om hun identiteit te achter-halen. Bij het verbreken van het contact geven social engineers een plausibel klinkende reden op, zodat het doelwit niet argwanend wordt.',
			],
		},
	},
	{
		id: 5,
		type: 3,
		color: '#00a1e5',
		points: 300,
		correct: null,
		group: 'Social Engineering',
		title: '“Social engineering is ook een belangrijk onderdeel van het zogeheten Business E-mail Compromise, waar of niet waar?”',
		text: '“Oplichters sturen vervalste e-mails die nauwelijks van echt te onderscheiden zijn en van een lid van het management of een zakelijke partner afkomstig lijken naar geselecteerde doelwitten. Via deze e-mails worden ze vervolgens aangespoord geld naar een frauduleuze rekening over te maken.”',
		correctAnswer: true,
		case: {
			headline: 'Fondsbeheerder Norfund uit Noorwegen verliest 10 miljoen dollar door Business E-Mail Compromise (BEC-fraude)',
			whatHappened: [
				'Norfund verkrijgt zijn investeringskapitaal uit de overheidsbegroting en is het grootste staatsinveste-ringsfonds ter wereld.',
				'De fondsbeheerder liet weten dat er door social engineering/BEC 10 miljoen Amerikaanse dollar aan schade is ontstaan.',
				'Met behulp van een mix van gemanipuleerde gegevens en vervalste informatie lukte het de oplichters om zich voor te doen als een leningverstrekkende instelling en om geldbedragen van de echte ontvanger naar de eigen rekening door te sluizen.',
				'Een sleutel tot ‘succes’ van de hackers was het misleiden van een leidinggevende. De leidinggevende liet uiteindelijk het geld overmaken naar een rekening die door de hackers werd beheerd.',
			],
		},
		takeAway: {
			headline: 'Opgebouwd vertrouwen als sleutel tot succes.',
			list: [
				'Bij Business E-mail Compromise worden de uitvoerende medewerkers bovendien onder tijdsdruk gezet en tot geheimhouding van de transactie gemaand. De daders bij het voorbeeld uit Noor-wegen hebben hun langetermijndoelen minutieus nagestreefd en er bewust voor gekozen ge-duldig te zijn, om zo een vertrouwensband op te kunnen bouwen.',
				'Social engineers proberen online informatie over jou en je werkomgeving te verkrijgen voordat ze contact opnemen. Geef daarom geen gevoelige persoons- of bedrijfsgegevens prijs aan on-bekende personen op socialmediaplatformen en bedrijfsportalen. Maak deze gegevens ook niet openbaar op dergelijke kanalen.',
			],
		},
	},
	{
		id: 6,
		type: 2,
		color: '#015380',
		points: 500,
		correct: null,
		group: 'Malware',
		title: 'Hoe schat je deze e-mail in en hoe ga je hiermee om?',
		text: '<p>“We gaan schetsen hoe de Norfund-case begonnen zou kunnen zijn. Laten we voor het gemak aannemen dat jij de medewerker bent, die de cybercriminelen als doelwit hebben gekozen.”</p><p>“Je zit achter je computer, het is druk op de werkvloer en plotseling krijg je een zeer urgente e-mail van een collega. Zoals wel vaker is er haast geboden. Bekijk de e-mail goed ...”</p><p>Twee dingen in de getoonde mail vallen op:</p><li>1.	De e-mail is niet afkomstig van een UNIPER-mailadres.</li><li>In de bijlage zit een .pptx-bestand.</li></ol>',
		options: [
			'Niets bijzonders, lijkt allemaal in orde. De e-mail lijkt op een UNIPER-mail en een .pptx-bestand in de bijlage roept bij mij geen vraagtekens op.',
			'Het e-mailadres van de afzender is geen adres van UNIPER. Maar omdat veel collega\'s momen-teel vanuit huis werken, kan het best eens gebeuren dat een collega vanuit zijn privémailadres mailt.',
			'Ik vertrouw het e-mailadres van de afzender niet helemaal en PowerPoint-bijlagen zijn potentiële risico\'s.',
		],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'Wees sceptisch als je wordt gevraagd om direct actie te ondernemen.',
			list: [
				'Laat je niet onder druk zetten. Zeker niet wanneer dit gepaard gaat met dreigementen.',
				'Neem de tijd om uit te pluizen hoe de vork in de steel zit.',
			],
		},
	},
	{
		id: 7,
		type: 2,
		color: '#f58f00',
		points: 1000,
		correct: null,
		group: 'Phishing',
		title: 'Welk wachtwoord is het sterkst?',
		text: '“Als vuistregel geldt: maak het de criminelen zo moeilijk mogelijk. Op die manier voorkom je dat ze toegang kun-nen krijgen tot je computer, het netwerk of de systemen. Dat geldt voor het voorbeeld van Norfund, maar zeker ook voor ons bij UNIPER. Een sterk wachtwoord is nog altijd een van de belangrijkste voorzorgsmaatregelen. Dus, een eenvoudige vraag: Welk wachtwoord is het sterkst?”',
		options: [
			'Iordermypizza@therestaurant4YOUnow!',
			'P04?§%\\wZ8@',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Gebruik een sterk wachtwoord dat je aan niemand doorvertelt.',
			list: [
				'Het moet uit ten minste twaalf tekens (letters, cijfers en speciale tekens) bestaan en mag niet in het woordenboek te vinden zijn.',
				'Als alternatief kun je woorden of zinsdelen vervormen (zoals: 2_D1epE#Borduh“).',
				'Een wachtwoordmanager helpt je veilige wachtwoorden op te stellen en te onthouden. Bij Uniper kunnen we via SNOW in de softwarestore KeePass kosteloos verkrijgen.',
			],
		},
	},
	{
		id: 8,
		type: 4,
		color: '#d3c002',
		points: 1000,
		correct: null,
		group: 'Social Engineering',
		title: '“Het is een gevoelskwestie!”',
		text: 'Wat de case Norfund echt bijzonder maakt, is het feit dat de daders gedurende meerdere maanden con-tact onderhielden met de onderneming en na de eerste succesvolle transactie zelfs een tweede poging deden om nog meer geld af te troggelen. Hierbij speelt een belangrijk aspect van social engineering een grote rol, een aspect waar deze praktijk zijn succes aan te danken heeft. Welk aspect wordt bedoeld?',
		options: [
			'emotie',
			'wilskracht',
			'concentratie',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Pas op, aanvallers spelen in op je emoties!',
			list: [
				'Ongeacht of het bericht via e-mail, telefoon, sms, een chatprogramma of social media binnenkomt: wees sceptisch zodra een bericht binnen een paar seconden een emotionele reactie bij je uitlokt en je verzoekt om direct tot actie over te gaan.',
			],
		},
	},
	{
		id: 9,
		type: 2,
		color: '#8cd4f4',
		points: 1000,
		correct: null,
		group: 'Malware',
		title: 'Wat wordt er bedoeld met een ‘drive-by download’?',
		text: 'Emoties spelen een doorslaggevende rol wanneer het om social engineering gaat. Een bericht moet je bijvoor-beeld zo ver brengen dat je op een bepaalde link klikt ...',
		options: [
			'Een drive-by download is het onbewust en onbedoeld downloaden van software op een computer: er wordt zonder actief medeweten van de gebruiker, ‘in het voorbijgaan’ een bestand gedownload.',
			'Bij een actieve download, bijvoorbeeld film- of muziekbestanden vanaf de bekende app-portals, worden bewust schadelijke programma\'s mee naar binnen ‘gesmokkeld’.',
			'Deze download is gelukkig iets positiefs: het gaat hierbij simpelweg om een effectief antivirusprogram-ma.',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Nooit klikken zonder na te denken!',
			list: [
				'Zowel bij het gebruik van privé-apparaten als zakelijke apparaten geldt:',
				'Klik nooit op verdachte links en controleer altijd of je met een betrouwbaar platform te maken hebt.',
				'Voor de veiligheid moet je altijd de actueelste versies van de browser en alle plug-ins (hulpprogramma\'s die de functies van een browser uitbreiden) gebruiken.',
			],
		},
	},
	{
		id: 10,
		type: 2,
		color: '#f58f00',
		points: 300,
		correct: null,
		group: 'Thuiswerken',
		title: 'Welke van deze drie groepen had een versterkt motief voor ‘onbevoegde toegang’?',
		text: 'De situatie heeft wat weg van de situatie die geschetst wordt bij ‘Thuiswerken’. Niet de onderneming zelf wordt aangevallen, maar de zogenaamde ‘buitenposten’. En dit gegeven vraagt gewoon om extra oplettendheid ...',
		options: [
			'hackers/criminelen',
			'politiek activist',
			'ontstemde oud-medewerker',
			'allemaal',
		],
		correctOptionIndex: 3,
		case: {
			headline: 'Datalek bij hotelketen Marriott',
			whatHappened: [
				'Twee datalekken bij deze onderneming binnen een korte tijd',
				'5,2 miljoenen gasten getroffen',
				'Twee medewerkers van een franchise van de hotelketen waren naar verluid de toegangspoort voor de datadieven',
				'Vermoedelijk lukte het de aanvallers om de logingegevens van de betreffende medewerkers te be-machtigen',
				'Door het lek kwamen gevoelige gegevens van hotelgasten op straat te liggen, waaronder paspoort-nummers en betaalgegevens',
			],
		},
		takeAway: {
			headline: 'Verschillende daders, verschillende motieven',
			list: [
				'Al deze groepen hebben in grote lijnen het doel om UNIPER of onze klanten schade toe te brengen:<ol><li>Bedrijfsspionnen > doorgifte van interne bedrijfsinformatie aan bijvoorbeeld vreemde mogendheden of concurrenten</li><li>Criminelen > diefstal van waardevolle voorwerpen, smartphones en tablets</li><li>Politieke activisten > het wekken van publieke belangstelling, vernietiging van eigendom</li><li>Terroristen > geweldsmisdrijven plegen op instellingen (filialen) en personen</li><li>Jaloerse klanten en medewerkers > verkrijgen van informatie en aandacht ten behoeve van de eigen belangen</li><li>Ontstemde oud-medewerkers > gericht ontvreemden van gevoelige gegevens om het bedrijf schade te berokkenen</li></ol>',
			],
		},
	},
	{
		id: 11,
		type: 2,
		color: '#d3c002',
		points: 300,
		correct: null,
		group: 'Malware',
		title: 'Wat is eigenlijk een ander woord voor malware, wat wordt er mee bedoeld?',
		text: 'Het moge duidelijk zijn dat malware nooit welkom is. Door uitgekiende aanvallen van criminelen krijgt malware namelijk onbevoegde toegang tot systemen. Wat is eigenlijk een synoniem voor malware, wat wordt er precies mee bedoeld?',
		options: ['schadelijk programma', 'kwaadaardige software', 'kwaadaardige software, schadelijk programma en malware betekenen hetzelfde'],
		correctOptionIndex: 2,
		takeAway: {
			headline: 'De termen kwaadaardige software, schadelijk programma en malware zijn synoniemen.',
			list: [
				'De termen kwaadaardige software, schadelijk programma en malware zijn overkoepelende begrippen voor een grote verscheidenheid aan computerprogramma\'s, die als doel hebben om onbevoegde toegang tot gegevens te verkrijgen door computers en systemen binnen te dringen.<br /><br />Belangrijke kenmerken:<ol><li>Zwakke plekken worden benut om eigen belangen te verwezenlijken</li><li>De programmacode wordt gemaskeerd of voortdurend veranderd om technische beveiligingssystemen te omzeilen</li><li>De ontwikkeling en het gebruik van malware gebeurt over het algemeen in een samenwerkingsver-band. Door de beschikbaarheid van malware op het dark web neemt de bedreiging gigantisch toe.</li></ol>',
			],
		},
	},
	{
		id: 12,
		type: 2,
		color: '#047fc7',
		points: 1000,
		correct: null,
		group: 'Thuiswerken',
		title: 'Wat wordt er niet bedoeld met informatieclassificatie?',
		text: 'Uit de tweede aanval op de Marriott-hotels is gebleken hoe belangrijk het is om de eigen gegevens goed te bevei-ligen – op kantoor, maar ook als er thuis wordt gewerkt. Informatieclassificatie helpt bij het beschermen van de eigen gegevens.',
		options: [
			'De vrije keuze voor een provider van telefoon- of videoconferenties.',
			'Zelf beoordelen welke beveiligingsmaatregelen er voor de informatie nodig zijn en zelf een classificatie-niveau toewijzen.',
		],
		correctOptionIndex: 0,
		takeAway: {
			headline: 'Informatieclassificatie als belangrijk beginsel',
			list: [
				'Het volgende is altijd van toepassing: UNIPER heeft concernbreed het beginsel voor de classificatie van informatie ingevoerd om te garanderen dat de beveiliging van de informatie op orde is en om het risico op gegevensverlies te verkleinen.',
				'Zodra je nieuwe informatie opstelt, informatie wijzigt of vanuit een externe bron opslaat of doorstuurt, ben je zelf verantwoordelijk voor het bepalen van de noodzakelijke beveiligingsmaatregelen.',
				'Het beginsel van informatieclassificatie is ook van toepassing op zakelijke telefoon- of videogesprekken die je vanuit thuis voert. Ga vertrouwelijk met deze gesprekken om wanneer er zakelijke informatie wordt besproken.',
				'Bij Uniper maken wij voor video- en telefoonconferenties in principe gebruik van Teams. Als je een al-ternatief programma moet gebruiken, kun je contact opnemen met je verantwoordelijke Information Security Officer (ISO)',
			],
		},
	},
]
