

















































































/* eslint-disable @typescript-eslint/ban-ts-comment */

import Vue from 'vue'

import Landing from './components/Landing.vue'
import LandingContent from './components/LandingContent.vue'
import OverviewGrid from './components/OverviewGrid.vue'
import Question from './components/Question.vue'
import Result from './components/Result.vue'
import Questions from './data/Questions'

export default Vue.extend({
	name: 'App',
	components: {
		OverviewGrid,
		Question,
		Landing,
		Result,
		LandingContent,
	},
	data() {
		return {
			landing: true,
			landing2: true,
			overview: true,
			current: -1,
			audio: null as any,
			audioPlaying: false,
			questions: Questions[this.$i18n.locale] || Questions.en,
			showLanguageSelection: false,
			showContinueGame: false,
			blockGoToStart: false,
		}
	},
	computed: {
		totalPoints(): number {
			return this.questions.filter((q) => q.correct === true).reduce((c, v) => c + v.points, 0)
		},
		totalPossiblePoints(): number {
			return this.questions.filter((q) => q.correct !== null).reduce((c, v) => c + v.points, 0)
		},
	},
	watch: {
		// eslint-disable-next-line func-names
		'$i18n.locale': function () {
			this.questions = (Questions[this.$i18n.locale] || Questions.en).map((question, idx) => ({
				...question,
				correct: this.questions[idx].correct,
			}))
		},
		questions: {
			deep: true,
			handler() {
				if (this.landing) {
					return
				}

				const userData: { current: number, selection: { [id: number]: any } } = {
					current: this.current,
					selection: {},
				}
				this.questions.forEach((question, idx) => {
					if (question.correct !== null) {
						userData.selection[idx] = question.correct ? question.correctOptionIndex : -1
					}
				})

				localStorage.setItem('gameState', JSON.stringify(userData))
			},
		},
	},
	mounted() {
		const language = localStorage.getItem('language')
		if (language) {
			this.$root.$i18n.locale = language
		}

		// eslint-disable-next-line global-require
		this.audio = new Audio(require('@/assets/audio/music.mp3'))
		if (typeof this.audio.loop === 'boolean') {
			this.audio.loop = true
		} else {
			this.audio.addEventListener('ended', () => {
				this.audio.currentTime = 0
				this.audio.play()
			}, false)
		}

		const gameStateJson = localStorage.getItem('gameState')
		if (gameStateJson) {
			this.showContinueGame = true
		}
	},
	methods: {
		selectQuestion(i: number) {
			console.log(i)
			this.current = i
			this.overview = false
		},
		newGame() {
			localStorage.removeItem('gameState')
			this.showContinueGame = false
		},
		continueGame() {
			const gameStateJson = localStorage.getItem('gameState')
			if (!gameStateJson) {
				this.showContinueGame = false
				return
			}
			const gameState = JSON.parse(gameStateJson)
			this.current = gameState.current

			this.questions = this.questions.map((question, idx) => {
				if (gameState.selection[idx]) {
					if (question.type === 3) {
						// eslint-disable-next-line no-param-reassign
						question.correct = Boolean(gameState.selection[idx]) === question.correctAnswer
					} else {
						// eslint-disable-next-line no-param-reassign
						question.correct = gameState.selection[idx] === question.correctOptionIndex
					}
				}
				return question
			})

			this.showContinueGame = false
			this.landing = false
			this.landing2 = false
		},
		selectLanguage(language: string) {
			this.$root.$i18n.locale = language
			this.showLanguageSelection = false
			localStorage.setItem('language', language)
		},
		toggleFullScreen() {
			if (
				!document.fullscreenElement // alternative standard method
				// @ts-ignore
				&& !document.mozFullScreenElement
				// @ts-ignore
				&& !document.webkitFullscreenElement
			) { // current working methods
				if (document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen()
					// @ts-ignore
				} else if (document.documentElement.mozRequestFullScreen) {
					// @ts-ignore
					document.documentElement.mozRequestFullScreen()
					// @ts-ignore
				} else if (document.documentElement.webkitRequestFullscreen) {
					// @ts-ignore
					document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
				}
				// @ts-ignore
			} else if (document.cancelFullScreen) {
				// @ts-ignore
				document.cancelFullScreen()
				// @ts-ignore
			} else if (document.mozCancelFullScreen) {
				// @ts-ignore
				document.mozCancelFullScreen()
				// @ts-ignore
			} else if (document.webkitCancelFullScreen) {
				// @ts-ignore
				document.webkitCancelFullScreen()
			}
		},
	},
})
