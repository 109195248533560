



































































/* eslint-disable vue/no-unused-components */
import Vue from 'vue'
import Info from './Info.vue'
import QuestionContent1 from './QuestionContent1.vue'
import QuestionContent2 from './QuestionContent2.vue'
import QuestionContent3 from './QuestionContent3.vue'
import QuestionContent4 from './QuestionContent4.vue'
import TakeAway from './TakeAway.vue'

export default Vue.extend({
	components: {
		Info,
		TakeAway,
		QuestionContent1,
		QuestionContent2,
		QuestionContent3,
		QuestionContent4,
	},
	name: 'Question',
	props: {
		question: {
			type: Object,
			required: true,
		},
		muted: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showInfo: this.question.case !== undefined,
			showTakeAway: false,
			answeredCorrectly: false,
			blockGoBack: false,
		}
	},
	methods: {
		correct() {
			this.$emit('blockGoToStart')
			this.blockGoBack = true
			if (!this.muted) {
				// eslint-disable-next-line global-require
				const audio = new Audio(require('@/assets/audio/correct.mp3'))
				audio.play()
			}

			this.answeredCorrectly = true
			setTimeout(() => {
				this.showTakeAway = true
			}, 1500)
		},
		incorrect() {
			this.$emit('blockGoToStart')
			this.blockGoBack = true
			if (!this.muted) {
				// eslint-disable-next-line global-require
				const audio = new Audio(require('@/assets/audio/incorrect.mp3'))
				audio.play()
			}

			setTimeout(() => {
				this.showTakeAway = true
			}, 1500)
		},
	},
})
