























































import Vue from 'vue'

export default Vue.extend({
	props: {
		points: {
			type: Number,
			default: 500,
		},
	},
})
