









































import Vue from 'vue'
import { GridItems } from '@/data/Questions'

export default Vue.extend({
	name: 'LandingContent',
	data() {
		return {
			gridItems: GridItems,
		}
	},
})
